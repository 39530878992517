import { z } from "zod";

// Schema -> data structure representation
export const AddContactFormSchema = z.object({
//  cellphone: z.string().nonempty("Digite o número de telefone do seu contato"),
email: z.string().nonempty("Insira um email para adicionar seu membro"),
//  defaultContact: z.boolean(),
});

// type to remove typescript errors by reactHooks
export type AddContactFormData = z.infer<typeof AddContactFormSchema>;
