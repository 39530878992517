import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div<{ $isLoading: boolean }>`
  position: absolute;
  inset: 0;
  background-color: #00000085;
  z-index: 5;

  display: ${(props) => (props.$isLoading ? "flex" : "none")};
  justify-content: center;
  align-items: center;

  span {
    width: 50px;
    height: 50px;

    border: 10px solid ${({ theme }) => theme.colors.neutral1100};
    border-radius: 50%;
    border-top-color: transparent;

    animation: ${rotate} 1s linear infinite;
  }
`;
