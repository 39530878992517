import React, { useState } from "react";

// styled components
import * as s from "./style";

// utils
import {
  logo,
  Install,
  Tutorial,
  support,
  Admin,
  Manager,
  Members,
  Clients,
} from "src/utils/icons";

// tooltip
import { Tooltip } from "react-tooltip";

// components
import { Modal } from "src/components/MainLayout/components/Table/Modal";

// radix
import { Flex } from "@radix-ui/themes";

// utils
import v1 from "src/utils/images/v1.png";
import v2 from "src/utils/images/v2.png";

import { useAuth } from "src/context/Auth/AuthContext";

// tabs props
interface ITabs {
  name: string;
  imageUrl: any;
  link: string;
  tooltip: string;
  role?: string;
}

interface IExternalTabs {
    name: string;
    imageUrl: any;
    link: string;
    tooltip: string;
    role?: string;
    state: boolean;
    stateFunction: (prevState: any) => any;
}

export const Sidebar = () => {
  const [installModal, setInstallModal] = useState<boolean>(false);
  const [tutorialModal, setTutorialModal] = useState<boolean>(false);
  const { user } = useAuth();

  const tabs: ITabs[] = [
    {
      name: "Números Cadastrados",
      imageUrl: 'clients.svg',
      link: "..",
      tooltip: "Meus Números",
    },
    {
      name: "Admin",
      imageUrl: 'admin.svg',
      link: "/admin",
      tooltip: "Página do admin",
      role: "admin",
    },
     {
       name: 'Gerente',
       imageUrl: 'manager.svg',
       link: '/manager',
       tooltip: 'Página do gerente',
       role:"manager"
     },
    {
      name: "Produtor",
      imageUrl: 'members.svg',
      link: "/members",
      tooltip: "Página do produtor",
      role: "productor",
    },
  ];

  const externalTabs: IExternalTabs[] = [
    {
      name: "Instalar",
      imageUrl: 'install.svg',
      link: "https://myleads.com.br/instalar",
      tooltip: "Instalar extensão MyLeads",
      state: installModal,
      stateFunction: setInstallModal
    },
    {
      name: "Tutorial",
      imageUrl: 'tutorial.svg',
      link: "https://myleads.com.br/tutoriais",
      tooltip: "Assista aos tutoriais",
      state: tutorialModal,
      stateFunction: setTutorialModal
    },
  ];

  return (
    <React.Fragment>
      <s.Sidebar>
        <s.NavContainer>
          <s.AltImage src={logo as any} alt="logo" />
          <nav>
            <s.List>
              {tabs.map(
                (tab, index) =>
                  (!tab.role ||
                    user?.profile === "admin" ||
                    user?.profile === tab.role) && (
                    <React.Fragment>
                      <s.ListItem
                        key={index}
                        to={tab.link}
                        data-tooltip-id={tab.name}
                        data-tooltip-content={tab.tooltip}
                      >
                        <img src={`/images/${tab.imageUrl}`} alt={tab.imageUrl} />
                      </s.ListItem>
                      <Tooltip
                        id={tab.name}
                        style={{ background: "rgb(15, 22, 30)" }}
                      />
                    </React.Fragment>
                  )
              )}

              {externalTabs.map((tab, index) => (
                <React.Fragment>
                  <s.ListItemState
                    onClick={() => tab.stateFunction(!tab.state)}
                    data-tooltip-id={tab.name}
                    data-tooltip-content={tab.tooltip}
                  >
                    <s.Img src={`/images/${tab.imageUrl}`} alt="" />
                  </s.ListItemState>
                  <Tooltip
                    id={tab.name}
                    style={{ background: "rgb(15, 22, 30)" }}
                  />
                </React.Fragment>
              ))}
            </s.List>
          </nav>
        </s.NavContainer>
        {/* <s.Hr /> */}
        <a
          href="https://myleads.com.br/suporte"
          target="_blank"
          rel="noreferrer"
          data-tooltip-id="support"
          data-tooltip-content="Fale com o suporte"
        >
          <s.AltImage src={support as any} alt="support" />
          <Tooltip id="support" style={{ background: "rgb(15, 22, 30)" }} />
        </a>
      </s.Sidebar>

      {installModal && (
        <Modal state={setInstallModal} css={{ width: "80%" }}>
          <s.InstallModal>
            <h4>Selecione a versão desejada:</h4>
            <Flex
              align="center"
              justify="between"
              gap='4'
              direction={window.screen.width >= 768 ? 'row' : 'column'}
              style={{ marginTop: "1rem" }}
            >
              <Flex direction="column">
                Versão antiga:
                <a
                  href="https://myleads.com.br/instalarantiga"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={v1}
                    alt=""
                    loading="lazy"
                  />
                </a>
              </Flex>

              <Flex direction="column">
                Versão nova:
                <a
                  href="https://myleads.com.br/instalarnova"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={v2}
                    alt=""
                    loading="lazy"
                  />
                </a>
              </Flex>
            </Flex>
          </s.InstallModal>
        </Modal>
      )}

      {tutorialModal && (
        <Modal state={setTutorialModal} css={{ width: "65%" }}>
          <s.InstallModal>
            <h4>Selecione a versão desejada:</h4>
            <Flex
               align="center"
               justify="between"
               gap='4'
               direction={window.screen.width >= 768 ? 'row' : 'column'}
               style={{ marginTop: "1rem" }}
            >
              <Flex direction="column">
                Versão antiga:
                <a
                  href="http://myleads.com.br/tutoriaisv1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={v1}
                    alt=""
                    loading="lazy"
                  />
                </a>
              </Flex>

              <Flex direction="column">
                Versão nova:
                <a
                  href="http://myleads.com.br/tutoriaisv2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={v2}
                    alt=""
                    loading="lazy"
                  />
                </a>
              </Flex>
            </Flex>
          </s.InstallModal>
        </Modal>
      )}
    </React.Fragment>
  );
};
