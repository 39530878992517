import React from "react";

function Alert() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <g clipPath="url(#clip0_597_21279)" filter="url(#filter0_d_597_21279)">
        <rect
          width="76"
          height="76"
          x="14"
          y="14"
          fill="#fff"
          rx="38"
          shapeRendering="crispEdges"
        ></rect>
        <rect
          width="75"
          height="75"
          x="14.5"
          y="14.5"
          stroke="#E5E9EE"
          rx="37.5"
          shapeRendering="crispEdges"
        ></rect>
        <path
          fill="#1A2633"
          fillRule="evenodd"
          d="M52.894 35.464a1 1 0 00-1.727.035L32.825 68.514A1 1 0 0033.7 70h38.518a1 1 0 00.853-1.522L52.894 35.464z"
          clipRule="evenodd"
          opacity="0.3"
        ></path>
        <rect width="4" height="14" x="50" y="46" fill="#1A2633" rx="1"></rect>
        <rect width="4" height="4" x="50" y="62" fill="#1A2633" rx="1"></rect>
      </g>
      <rect
        width="103"
        height="103"
        x="0.5"
        y="0.5"
        stroke="#F7F8FA"
        rx="51.5"
      ></rect>
      <defs>
        <filter
          id="filter0_d_597_21279"
          width="106"
          height="106"
          x="-1"
          y="4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="5"
            result="effect1_dropShadow_597_21279"
          ></feMorphology>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.101961 0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0.05 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_597_21279"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_597_21279"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_597_21279">
          <rect width="104" height="104" fill="#fff" rx="52"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Alert;