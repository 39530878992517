import React from "react";

function Close() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#92A1B1"
        d="M2.226.811A1 1 0 10.81 2.225L6.586 8 .812 13.775a1 1 0 101.414 1.414L8 9.414l5.775 5.775a1 1 0 001.414-1.414L9.415 8l5.774-5.775A1 1 0 1013.775.811L8 6.586 2.226.81z"
      ></path>
    </svg>
  );
}

export default Close;
