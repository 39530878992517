import styled from "styled-components";

export const TableContainer = styled.table`
  width: 100%;
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 0 24px;
  table-layout: fixed;

  @media (max-width: 780px) {
    position: relative;
    margin-top: 0;
  }
`;

export const TableHead = styled.thead`
  line-height: 55px;
  border-bottom: 1px solid red;

  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral500};
  font-weight: 500;

  td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral900};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

export const TableBody = styled.tbody`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral200};
  font-weight: 400;

  tr {
    position: relative;
  }

  td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
    padding: 0.35rem 0.35rem 0.35rem 0;
  }

  td:nth-child(1) {
    div {
      display: flex;
      align-items: center;
      gap: 1rem;

      img {
        border-radius: 50px;
      }
    }
  }

  td:nth-child(3) {
    div {
      display: flex;
      gap: 4px;
    }
  }

  @media (max-width: 780px) {
    display: block;

    tr,
    td {
      /* overflow: visible; */
      padding: 10px 0 10px 10px;
      display: block;
      overflow: visible;
    }

    tr {
      border-bottom: 1px solid ${({ theme }) => theme.colors.neutral900};
      padding-bottom: 1.25rem;
      margin-bottom: 1.25rem;
    }

    td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 30%;
      text-align: left;
      font-weight: bold;
    }

    td {
      padding: 0;
      padding-left: 35%;
      position: relative;
    }
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 35px;
  right: 0;

  width: 200px;
  height: auto;

  border-radius: 10px;
  padding: 8px;

  background-color: ${({ theme }) => theme.colors.neutral1100};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  z-index: 1;

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;

    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral400};
    list-style: none;

    li {
      padding: 8px;
    }
  }

  @media (max-width: 780px) {
    top: 0;
    right: auto;
    margin-left: 40px;
    width: auto;
  }
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  h1,
  h2 {
    text-align: center;
  }

  button {
    width: 300px;
    height: 42px;
    border: 0;
    border-radius: 50px;
    padding: 0 24px;
    background-color: ${({ theme }) => theme.colors.neutral100};
    color: ${({ theme }) => theme.colors.neutral1100};
    cursor: pointer;
  }

  span {
    color: ${({ theme }) => theme.colors.neutral500};
    font-size: 14px;
    cursor: pointer;
  }
`;
