import React from "react";

function RecoveryPassswordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <g clipPath="url(#clip0_254_2207)" filter="url(#filter0_d_254_2207)">
        <rect
          width="76"
          height="76"
          x="14"
          y="14"
          fill="#fff"
          rx="38"
          shapeRendering="crispEdges"
        ></rect>
        <rect
          width="75"
          height="75"
          x="14.5"
          y="14.5"
          stroke="#E5E9EE"
          rx="37.5"
          shapeRendering="crispEdges"
        ></rect>
        <path
          fill="#445568"
          fillRule="evenodd"
          d="M39.763 30.076a.5.5 0 00-.87.205L36.25 41.363a.5.5 0 00.515.615l11.4-.646a.5.5 0 00.355-.82l-3.023-3.603A16.99 16.99 0 0151 36c9.389 0 17 7.611 17 17s-7.611 17-17 17-17-7.611-17-17c0-1.576.213-3.12.63-4.604l-3.852-1.08A21.016 21.016 0 0030 53c0 11.598 9.402 21 21 21s21-9.402 21-21-9.402-21-21-21c-2.917 0-5.696.595-8.22 1.67l-3.017-3.594z"
          clipRule="evenodd"
          opacity="0.3"
        ></path>
        <path
          fill="#445568"
          fillRule="evenodd"
          d="M47 46.5a2.5 2.5 0 012.5-2.5h5a2.5 2.5 0 012.5 2.5V50h1a1 1 0 011 1v8a1 1 0 01-1 1H46a1 1 0 01-1-1v-8a1 1 0 011-1h1v-3.5zm3.5-.5a1.5 1.5 0 00-1.5 1.5V50h6v-2.5a1.5 1.5 0 00-1.5-1.5h-3z"
          clipRule="evenodd"
        ></path>
      </g>
      <rect
        width="103"
        height="103"
        x="0.5"
        y="0.5"
        stroke="#F7F8FA"
        rx="51.5"
      ></rect>
      <defs>
        <filter
          id="filter0_d_254_2207"
          width="106"
          height="106"
          x="-1"
          y="4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="5"
            result="effect1_dropShadow_254_2207"
          ></feMorphology>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.101961 0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0.05 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_254_2207"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_254_2207"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_254_2207">
          <rect width="104" height="104" fill="#fff" rx="52"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default RecoveryPassswordIcon;
