// hooks
import { useEffect } from "react"

// layout
import FormLayout from "src/layout/Form"

// react-hook-forms
import { useForm } from 'react-hook-form'

// zod
import { RegisterFormData, RegisterFormSchema } from 'src/types/register-form'
import { zodResolver } from '@hookform/resolvers/zod'

// mask
import { normalizePhoneNumber } from "src/utils/input-mask"

// components
import { Header } from "src/components/Header"
import Input from 'src/components/Input'
import { Submit } from 'src/components/Submit'

// style
import * as s from '../Login/style'
import { useAuth } from "src/context/Auth/AuthContext"
import { toast } from "react-toastify"

export const Register = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }, // need to show the form validation errors
  } = useForm<RegisterFormData>({
    resolver: zodResolver(RegisterFormSchema)
  })
  const {setLoading,handlerRegister} = useAuth();
  // cellphone number mask
  const phoneValue = watch('cellphone')
  useEffect(() => {
    setValue('cellphone', normalizePhoneNumber(phoneValue))
  }, [phoneValue, setValue])

  const onSubmit = async (data: RegisterFormData) => {
   try{
    await handlerRegister(data);
    toast.success("successo ao criar");
   }catch{
    toast.error("falha ao se registrar")
    setLoading(false)
   }
  }

  return (
    <FormLayout>
      <s.Wrapper>
        <Header
          title={'Crie sua conta'}
          subTitle={'Insira os dados corretamente para realizar o cadastro.'}
        />

        <s.Form onSubmit={handleSubmit(onSubmit)}>
          <s.InputContainer>
            <Input
              type='name'
              placeholder="Insira seu nome e sobrenome"
              id="name"
              register={register}
              errors={errors.name}
            >
              Nome e sobrenome
            </Input>

            <Input
              type='cellphone'
              id="cellphone"
              placeholder="Insira seu telefone de contato"
              register={register}
              errors={errors.cellphone}
            >
              Telefone
            </Input>

            <Input
              type='email'
              id="email"
              placeholder="Insira seu e-mail"
              register={register}
              errors={errors.email}
            >
              E-mail
            </Input>

            <Input
              type='password'
              id="password"
              placeholder='Insira sua senha'
              register={register}
              errors={errors.password}
            >
              Senha
            </Input>

            <Input
              type='password'
              id="repeatPassword"
              placeholder='Insira sua senha novamente'
              register={register}
              errors={errors.repeatPassword}
            >
              Confirme sua senha
            </Input>
          </s.InputContainer>

          <s.SubmitContainer>
            <Submit>
              Criar conta
            </Submit>

            <s.Span>
              Já tem uma conta?
              <s.ChangeRoute to='..'>
                Faça o login
              </s.ChangeRoute>
            </s.Span>
          </s.SubmitContainer>
        </s.Form>
      </s.Wrapper>
    </FormLayout>
  )
}