// hooks
import { useState } from 'react'

// style
import * as s from './style'
import { useAuth } from 'src/context/Auth/AuthContext'

export const Spinner = () => {
  const {loading:isLoading} = useAuth()

  return (
    <s.Container $isLoading={isLoading}>
      <span></span>
    </s.Container>
  )
}