// react
import { ReactNode, useEffect, useRef, useState } from 'react'

// styles
import * as s from './style'
import Close from 'src/utils/icons/Close'

// types
interface ButtonProps {
  icon?: ReactNode,
  label: string,
  children?: ReactNode,
  primary?: boolean,
  secondary?:boolean,
  buttonStyle?: React.CSSProperties;
  disabled?: boolean,
  ghost?: boolean,
  dropdownDisabled?: boolean,
  dropdownTitle?: string,
  dropdownSizeW?: string,
  dropdownSizeH?: string,
  setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Button = ({
  label,
  icon,
  children,
  primary,
  disabled,
  ghost,
  buttonStyle,
  dropdownDisabled,
  dropdownTitle,
  dropdownSizeW,
  dropdownSizeH,
  secondary,
  setShowDeleteModal,
}: ButtonProps, { ...props }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  // close when click out
  const buttonRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [buttonRef]);

  const handleDropdown = () => {
    if(disabled) return;
    setShowDeleteModal?.(true);
    if (label === "Filtro") {
      setDropdownOpen(!dropdownOpen);
    }
  };

  return (
    <div style={{ position: 'relative' }} ref={buttonRef}>
      <s.Button style={buttonStyle} $secondary={secondary} $primary={primary} $isDisabled={disabled} $ghost={ghost} onClick={handleDropdown}>
        {icon}
        {label}
      </s.Button>

      {(dropdownOpen && !disabled && !dropdownDisabled) && (
        <s.Dropdown $sizeH={dropdownSizeH} $sizeW={dropdownSizeW}>
          <s.DropdownHeader>
            <p>{dropdownTitle}</p>
            <span onClick={handleDropdown}>
              <Close />
            </span>
          </s.DropdownHeader>
          <div style={{ maxHeight: dropdownSizeH }}>
            {children}
          </div>
        </s.Dropdown>
      )}
    </div>
  )
}