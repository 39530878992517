// layout
import FormLayout from "src/layout/Form"

// react-hook-forms
import { useForm } from 'react-hook-form'

// zod
import { LoginFormData, LoginFormSchema } from 'src/types/login-form'
import { zodResolver } from '@hookform/resolvers/zod'

// components
import { Header } from "src/components/Header"
import { Submit } from "src/components/Submit"
import Input from "src/components/Input"

// styles
import * as s from './style'
import { useAuth } from "src/context/Auth/AuthContext"

export const Login = () => {
  const { handleLogin } = useAuth()
  const {
    register,
    handleSubmit,
    formState: { errors }, // need to show the form validation errors
  } = useForm<LoginFormData>({
    resolver: zodResolver(LoginFormSchema)
  })

  const onSubmit = (data: LoginFormData) => {
    handleLogin(data)
  }

  return (
    <FormLayout>
      <s.Wrapper>
        <Header
          title={'Acesse sua conta'}
          subTitle={'Insira seu e-mail e senha para realizar o login.'}
        />
        <s.Form onSubmit={handleSubmit(onSubmit)}>
          <s.InputContainer>
            <Input
              type='email'
              placeholder="Insira seu e-mail"
              register={register}
              errors={errors.email}
            >
              E-mail
            </Input>

            <Input
              type='password'
              placeholder='Insira sua senha'
              register={register}
              errors={errors.password}
            >
              Senha
            </Input>

            <s.ChangeRoute
              to='./forgot-password'
              style={{ color: '#738699', textDecoration: 'none' }}
            >
              Esqueceu a senha?
            </s.ChangeRoute>
          </s.InputContainer>

          <s.SubmitContainer>
            <Submit>
              Acessar
            </Submit>

            <s.Span>
              Não tem conta?
              <s.ChangeRoute to='./register'>
                Cadastre-se
              </s.ChangeRoute>
            </s.Span>
          </s.SubmitContainer>
        </s.Form>
      </s.Wrapper>
    </FormLayout>
  )
}