// hooks
import { useEffect, useRef, useState } from 'react'

// styles
import * as s from 'src/components/MainLayout/style'

// components
import { Button } from 'src/components/MainLayout/components/Button'
import { AddUser } from 'src/components/MainLayout/components/Controls/AddContact'
import { ImportContacts } from 'src/components/MainLayout/components/Controls/ImportContacts'
import { Filter } from 'src/components/MainLayout/components/Controls/Filter'
import { Table } from 'src/components/MainLayout/components/Table'
import { FilterCount } from 'src/components/FilterCount'
import { SkeletonTable } from 'src/components/MainLayout/components/Table/Skeleton'
import openSocket from "../../services/socket-io";
// radix
import { Tabs } from '@radix-ui/themes'

// utils
import {
  ExportIcon,
  FilterIcon,
  ImportIcon,
  PlusIcon,
  TrashCanIcon,
  // UserProfilePicture, TODO: only on PRO
  EmptyData,
  UnblockContacts,
} from 'src/utils/icons'

import { DataProps } from './data'
import api from 'src/services/api'
import { useAuth } from 'src/context/Auth/AuthContext'
import { Modal } from 'src/components/MainLayout/components/Table/Modal'
import { toast } from 'react-toastify'
import { Memberss } from './members'

export const Manager = () => {
  const pageReference = useRef<HTMLDivElement>(null);

  return (
    <s.Container defaultValue='contacts'>
      <s.Content>
        <h1>Opções</h1>

        <s.TabsList>
          <s.TabsTrigger value="contacts">Meus Clientes</s.TabsTrigger>
          {//<s.TabsTrigger value="blocked-contacts">Backups</s.TabsTrigger>
          }
        </s.TabsList>
      </s.Content>

      <s.Content ref={pageReference} $primary={true}>
        <Tabs.Content value='contacts'>
          <Memberss pageReference={pageReference} tab='members' />
        </Tabs.Content>
        <Tabs.Content value='blocked-contacts'>
          <Memberss tab='backup' />
        </Tabs.Content>
      </s.Content>
    </s.Container>
  )
}