// hooks
import { useCallback, useEffect, useState } from 'react'

// styles
import * as s from 'src/components/MainLayout/style'

// components
import { Button } from 'src/components/MainLayout/components/Button'
import { ImportContacts } from 'src/components/MainLayout/components/Controls/ImportContacts'
import { Filter } from 'src/components/MainLayout/components/Controls/FilterManager'
import { Table } from 'src/components/MainLayout/components/TableManager'
import { FilterCount } from 'src/components/FilterCount'
import { SkeletonTable } from 'src/components/MainLayout/components/Table/Skeleton'
import openSocket from "../../services/socket-io";
// radix
import { Tabs } from '@radix-ui/themes'

// utils
import {
  ExportIcon,
  FilterIcon,
  ImportIcon,
  PlusIcon,
  TrashCanIcon,
  // UserProfilePicture, TODO: only on PRO
  EmptyData,
  UnblockContacts,
} from 'src/utils/icons'

import { DataProps } from './data'
import api from 'src/services/api'
import { useAuth } from 'src/context/Auth/AuthContext'
import { Modal } from 'src/components/MainLayout/components/Table/Modal'
import { toast } from 'react-toastify'
import { AddMember } from 'src/components/MainLayout/components/Controls/AddMember'

interface MembersProperties {
  pageReference?: React.RefObject<HTMLDivElement>;
  tab: string
}

export const Memberss = ({ tab, pageReference }: MembersProperties) => {
  const [addUser, setAddUser] = useState<boolean>(false)
  const [data, setData] = useState<DataProps[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<DataProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true)
  const { user } = useAuth()
  const [count, setCount] = useState(0);
  const [defaultN, setDefaultN] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, SetPage] = useState(0);
  const [isLoading, setIsloading] = useState(false)
  const [text, SetText] = useState("");
  const [totalOnline, SetOnlineTotal] = useState(0);
  const [onlineN, SetOnlineN] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [V2, SetV2] = useState(false);
  const [filter, SetFilter] = useState({ online: false, total: false });
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const bulkDelete = async () => {
    const contacts = selectedContacts.map(element => element.id)
    try {
      selectedContacts.length > 0 && await api.post("/members", {
        contacts
      });
      setData?.(data.filter(elmenet => !contacts.includes(elmenet.id)));
      setSelectedContacts([])
      toast.success("sucesso ao deletar")
    } catch {
      toast.error("falha ao deletar")
    }
  }
  const searchFunc = (string:string,expected:string) =>{
    return string.toLocaleLowerCase().search(expected.toLocaleLowerCase()) !== -1
      }
  useEffect(() => {
    refresh()
  }, [user, page])
  function refresh(texto?: string, defaultn?: boolean, onlineM?: boolean, V2n?: boolean,PageP?:boolean) {
    if (user) {
      (async () => {
        if (isLoading) return;
        setLoading(true)
        setIsloading(true)
        setSelectedContacts([]);
        let { data: newdata } = await api.get("/manager", { params: { texto:typeof texto =="string"? texto : text,charge:V2n !== undefined ? V2n : V2,expired:onlineM !== undefined ? onlineM : onlineN,active:defaultn !== undefined ? defaultn : defaultN, page:PageP?0:page } });
        setCount(newdata.count)
        setTotal(newdata.count)
        if(PageP){
          SetPage(0);
        }
        if ((texto !== undefined && texto !== "") || defaultn || onlineM) {
          if ((texto !== undefined && texto !== "") || defaultn) {
            SetFilter({ total: true, online: onlineM ? onlineM : false })
          } else {
            SetFilter({ total: true, online: false })
          }
        } else {
          SetFilter({ online: false, total: false });
        }
        SetOnlineTotal(newdata.totalOnline)
        setHasMore(newdata.hasMore)
        let resultados = newdata.rows.map((element: any) => {
          return {
            name: element.name,
            email: element.email,
            number: element.number,
            id: element.id,
            plan:element.plan,
            AddDate: element.createdAt,
            checked: false,
            whatsapp: element.status
          }
        })
        setData(a=>[...PageP?[]:a.map(e=>{
          const resultado = resultados.find((element:any)=>element.id == e.id);
          resultados=resultados.filter((element:any)=>element.id !== e.id)
          return {...e,...resultado}
        }),...resultados])
        setLoading(false)
        setIsloading(false)
      })()
    }
  }

  const loadMore = () => {
    SetPage((prevState) => prevState + 1);
  };

  const onScroll = useCallback((e: React.UIEvent) => {
    if (!hasMore || isLoading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }

  }, [hasMore, isLoading])

  useEffect(() => {
    if (pageReference?.current && (!pageReference?.current.onscroll || hasMore)) {
      pageReference.current.onscroll = (onScroll as any)
    }
  }, [onScroll, pageReference, hasMore])


  return (
    <>{/*<h1>Área de Membros</h1>
  <h2>Você poderá definir quais membros estarão autorizados a usar seu backup na MyLeads Members.</h2>*/}
      <s.Divider />
      <div>
        <header>
          <s.ListTotal>
            <li>Total: <span>{count}{count !== total && ` | ${total}`}</span></li>
          </s.ListTotal>

          <s.ButtonWrapper>
          {/*  <span onClick={() => setAddUser(true)}>
              <Button
                icon={<PlusIcon />}
                label='Adicionar'
                primary={true}
                dropdownDisabled
              // dropdownTitle='Adicionar contato'
              // dropdownSizeW='340px'
              // dropdownSizeH='500px'
              />
  </span>*/}

            <Button
              icon={<FilterIcon />} label='Filtro'
              dropdownTitle='Filtro'
              dropdownSizeW={ document.documentElement.offsetWidth > 450 ? '340px' : '200px'}
              dropdownSizeH='400px'
            >
              <Filter V2={V2} onlineN={onlineN} defaultN={defaultN} text={text} tab={tab} SetV2={SetV2} SetText={SetText} refresh={refresh} setDefaultN={setDefaultN} SetOnlineN={SetOnlineN} />
            </Button>

            {/*<Button
                  icon={<ExportIcon />}
                  label='Exportar'
                  disabled={data.length >= 1 ? false : true}
                  dropdownDisabled
                >
                  Exportar
                </Button>

                <Button
                  icon={<ImportIcon />}
                  label='Importar'
                  dropdownTitle='Importar contatos'
                  dropdownSizeW='600px'
                  dropdownSizeH='500px'
                >
                  <ImportContacts />
                </Button>*/}

         {/*   <Button
              setShowDeleteModal={setShowDeleteModal}
              secondary={true}
              icon={<TrashCanIcon />}
              ghost={selectedContacts.length === 0 ? true : false}
              disabled={selectedContacts.length !== 0 ? false : true}
              label='Excluir'
              dropdownDisabled={selectedContacts.length === 0 ? true : false}
            >
              Excluir
              </Button>*/}
          </s.ButtonWrapper>
        </header>
      </div>

      <div>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            {data.length <= 0 ? (
              <s.EmptyDataContainer>
                <EmptyData />
                <h2>Você ainda não cadastrou nenhum membro</h2>
                <h3>Seus Membros só podem ser visualizados após serem adicionados.</h3>
              </s.EmptyDataContainer>
            ) : (
              <Table
                data={data}
                showDeleteModal={showDeleteModal}
                bulkDelete={bulkDelete}
                refresh={refresh}
                setData={setData}
                setShowDeleteModal={setShowDeleteModal}
                setSelectedContacts={setSelectedContacts}
              />
            )}
          </>
        )}
      </div>
      {addUser && (
        <Modal state={setAddUser}>
          <AddMember onCancel={() => setAddUser(false)} refresh={refresh} />
        </Modal>
      )}</>
  )
}