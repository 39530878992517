import React from "react";

function TrashCanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.5 7.5v5.25m-3-5.25v5.25m-3-8.25v8.85c0 .84 0 1.26.163 1.58a1.5 1.5 0 00.656.657c.32.163.74.163 1.579.163h4.204c.839 0 1.258 0 1.579-.163.282-.144.512-.374.656-.656.163-.32.163-.74.163-1.579V4.5m-9 0H6m-1.5 0H3m3 0h6m-6 0c0-.699 0-1.048.114-1.324a1.5 1.5 0 01.812-.812C7.2 2.25 7.55 2.25 8.25 2.25h1.5c.699 0 1.048 0 1.324.114a1.5 1.5 0 01.812.812C12 3.452 12 3.801 12 4.5m0 0h1.5m0 0H15"
      ></path>
    </svg>
  );
}

export default TrashCanIcon;