import styled from "styled-components";

export const ModalPage = styled.div`
  position: fixed;
  width: 60%;
  left: calc(50% - 30%);
  max-height: 60vh;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 6px;
  display: flex;
  flex-direction: column;
  padding: 0.2em; 
  z-index: 2;
  border-radius: 6px;

  .body--modal {
    padding: 1em;
  }

  iframe {
    border-radius: 6px;
    min-height: 300px;
  }
`;

export const Title = styled.h2`
  font-weight: bold;
`;

export const Description = styled.p`

`;

export const Exit = styled.button`
  background-color: #1A2633;
  font-weight: bold;
  color: #fff;
  border-radius: 50%;
  align-self: flex-end;
  border: none;
  padding: 0.4em 0.5em;
  cursor: pointer;
`;