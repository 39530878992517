import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const Reset = styled.input.attrs({ type: "reset" })`
  flex: 2;
  padding: 13px 16px;
  border-radius: 50px;

  background-color: ${({ theme }) => theme.colors.neutral900};
  color: ${({ theme }) => theme.colors.neutral100};

  border: 0;
  cursor: pointer;
`;

export const Submit = styled.input.attrs({ type: "submit" })`
  flex: 3;
  padding: 13px 16px;
  border-radius: 50px;

  background-color: ${({ theme }) => theme.colors.neutral100};
  color: ${({ theme }) => theme.colors.neutral1100};

  border: 0;
  cursor: pointer;
`;

export const InputContainer = styled.div<{ $hasError?: boolean }>`
  label {
    color: ${(props) => (props.$hasError ? "#445568" : "#738699")};
    font-weight: 500;
    font-size: 14px;
  }

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral300};
  }
`;
