import { ReactNode } from 'react'

// styled-components
import * as s from './style'

// components
import { Content } from './components/Content'

export default function FormLayout({ children }: { children: ReactNode }) {
  return (
    <s.Container>
      {children}
    </s.Container>
  )
}