import styled, { css } from "styled-components";

import { Link } from "react-router-dom";

export const Sidebar = styled.aside`
  background: ${({ theme }) => theme.colors.neutral100};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 1.5rem 1rem;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 18%;
  
  z-index: 1;

  @media (min-width: 768px) {
    width: 10%;
    top: .5rem;
    left: .5rem;
    bottom: .5rem;
    border-radius: 1.25rem;
  }
  
  @media (min-width: 1024px) {
    max-height: calc(100vh - 2rem);
    width: 5%;
    top: 1rem;
    left: 1rem;
    bottom: 1rem;
    margin-right: 1rem;
  }
`;

export const NavContainer = styled.div`
  text-align: center;

  display: flex;
  align-items: center;
  flex-direction: column;

  overflow: hidden;
`;

export const List = styled.ul`
  margin-top: 2rem;
  list-style: none;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  padding-left: 0;

  @media (min-width: 1024px) {
    gap: .3rem;
  }
`;

export const Img = styled.img`
  transform: scale(0.85);

  @media (min-width: 1440px) {
    transform: scale(0.95);
  }
`;

export const AltImage = styled.img`
  transform: scale(0.5);

  @media (min-width: 1024px) {
    transform: scale(0.65);
  }

  @media (min-width: 1440px) {
    transform: scale(0.95);
  }
`;

const ListItemStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;

  border: 0.0625rem solid ${({ theme }) => theme.colors.neutral100};
  border-radius: 0.625rem;

  cursor: pointer;
  transition: 0.3s;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral300};
    border-color: ${({ theme }) => theme.colors.neutral400};

    ${Img} {
      filter: brightness(10);
    }

    img {
      filter: brightness(2);
    }
  }

  @media (min-width: 1024px) {
    width: 2.85rem;
    height: 2.85rem;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 1440px) {
    width: 3.25rem;
    height: 3.25rem;
  }
`;

export const ListItem = styled(Link)`
  ${ListItemStyles}
`;

export const ListItemState = styled.div`
  ${ListItemStyles}
`;

export const Hr = styled.hr`
  width: 45%;
  border: 0;
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.neutral300};
`;

export const InstallModal = styled.div`
  img {
    transition: filter 0.3s;
    border-radius: 5px;
    width: 100%;
  }

  a {
    width: 95%;

    &:hover {
      img {
        filter: brightness(0.7);
      }
    }
  }
`;
