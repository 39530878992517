import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  h2,
  h3 {
    text-align: center;
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral100};
  }

  h3 {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral500};
  }
`;
