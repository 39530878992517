import React from "react";

function FilterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#1A2633"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15 4.2c0-.42 0-.63-.082-.79a.749.749 0 00-.327-.328C14.43 3 14.22 3 13.8 3H4.2c-.42 0-.63 0-.79.082a.75.75 0 00-.328.327C3 3.57 3 3.78 3 4.2v.553c0 .183 0 .275.02.362a.75.75 0 00.09.216c.047.076.112.14.242.27L7.149 9.4c.13.13.194.194.24.27.041.067.072.14.09.216.021.086.021.177.021.356v3.567c0 .643 0 .965.135 1.158.119.17.301.282.505.312.234.035.522-.109 1.097-.396l.6-.3c.24-.12.36-.181.448-.27a.75.75 0 00.174-.28c.041-.12.041-.255.041-.524v-3.261c0-.184 0-.275.02-.361a.753.753 0 01.09-.217c.047-.076.111-.14.239-.268l.003-.002 3.797-3.797a1.6 1.6 0 00.24-.27.752.752 0 00.09-.217C15 5.029 15 4.938 15 4.759V4.2z"
      ></path>
    </svg>
  );
}

export default FilterIcon;