import React from "react";

function UnblockContacts() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.75 6.75H5.4c-.84 0-1.26 0-1.581.163a1.5 1.5 0 00-.656.656C3 7.889 3 8.31 3 9.15v4.2c0 .84 0 1.26.163 1.58a1.5 1.5 0 00.656.657c.32.163.74.163 1.579.163h7.204c.839 0 1.258 0 1.579-.163.282-.144.512-.374.656-.656.163-.32.163-.74.163-1.578V9.148c0-.839 0-1.259-.163-1.58a1.5 1.5 0 00-.656-.655c-.321-.163-.74-.163-1.58-.163H6.75zm0 0V4.59c0-1.292.975-2.34 2.178-2.34.618 0 1.175.277 1.571.72"
      ></path>
    </svg>
  );
}

export default UnblockContacts;
