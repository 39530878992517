import { z } from "zod";

// Schema -> data structure representation
export const AddContactFormSchema = z.object({
//  cellphone: z.string().nonempty("Digite o número de telefone do seu contato"),
name: z.string().nonempty("Insira o Titulo da Notificação que deseja adicionar"),
nameen: z.string().nonempty("Insira o Titulo da Notificação que deseja adicionar"),
namees: z.string().nonempty("Insira o Titulo da Notificação que deseja adicionar"),
Text: z.string().nonempty("Insira o Texto da Notificação que deseja adicionar"),
Textes: z.string().nonempty("Insira o Texto da Notificação que deseja adicionar"),
Texten: z.string().nonempty("Insira o Texto da Notificação que deseja adicionar"),
'button-title0': z.string().optional(),
'button-title1': z.string().optional(),
'button-title2': z.string().optional(),
'button-link0': z.string().optional(),
'button-link1': z.string().optional(),
'button-link2': z.string().optional()
//  defaultContact: z.boolean(),
});

// type to remove typescript errors by reactHooks
export type AddContactFormData = z.infer<typeof AddContactFormSchema>;
