// hooks
import { useCallback, useEffect, useState } from 'react'

// styles
import * as s from 'src/components/MainLayout/style'

// components
import { Button } from 'src/components/MainLayout/components/Button'
import { ImportContacts } from 'src/components/MainLayout/components/Controls/ImportContacts'
import { Filter } from 'src/components/MainLayout/components/Controls/FilterAdmin/freetest'
import { Table } from 'src/components/MainLayout/components/TableAdmin/index2'
import { FilterCount } from 'src/components/FilterCount'
import { SkeletonTable } from 'src/components/MainLayout/components/Table/Skeleton'
import openSocket from "../../services/socket-io";
import * as XLSX from "xlsx";
// radix
import { Tabs } from '@radix-ui/themes'

// utils
import {
  ExportIconBranco,
  FilterIcon,
  ImportIcon,
  PlusIcon,
  TrashCanIcon,
  // UserProfilePicture, TODO: only on PRO
  EmptyData,
  UnblockContacts,
} from 'src/utils/icons'

import { DataProps } from './data'
import api from 'src/services/api'
import { useAuth } from 'src/context/Auth/AuthContext'
import { Modal } from 'src/components/MainLayout/components/Table/Modal'
import { toast } from 'react-toastify'
import { AddUser } from 'src/components/MainLayout/components/Controls/AddUser/freetest'

interface MembersProperties {
  pageReference?: React.RefObject<HTMLDivElement>;
  tab: string
}

export const FreeTest = ({ tab, pageReference }: MembersProperties) => {
  const [addUser, setAddUser] = useState<boolean>(false)
  const [data, setData] = useState<DataProps[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<DataProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true)
  const { user,setLoading:setl } = useAuth()
  const [count, setCount] = useState(0);
  const [defaultN, setDefaultN] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, SetPage] = useState(0);
  const [isLoading, setIsloading] = useState(false)
  const [text, SetText] = useState("");
  const [totalOnline, SetOnlineTotal] = useState(0);
  const [onlineN, SetOnlineN] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [V2, SetV2] = useState(false);
  const [profile,SetProfile] = useState<boolean|undefined>(undefined)
  const [status,SetStatus] = useState<string|undefined>("null")
  const [role,SetRole] = useState<boolean|undefined>(undefined)
  const [affiliated,SetAffiliated] = useState<string|undefined>("null")
  const [filter, SetFilter] = useState({ online: false, total: false });
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  useEffect(() => {
    refresh()
  }, [user, page])
  function refresh(texto?: string, defaultn?: boolean, onlineM?: boolean, V2n?: boolean,PageP?:boolean,rolegaem?:boolean,statusem?:string,affiliated2?:string) {
    if (user) {
      (async () => {
        if (isLoading) return;
        setLoading(true)
        setIsloading(true)
        setSelectedContacts([]);
        let { data: newdata } = await api.get("/listfreetest", { params: { searchParam:typeof texto =="string"? texto : text,role:typeof rolegaem == "boolean"?rolegaem:role,status:statusem?statusem:status, pageNumber:(PageP?0:page)+1,plano:undefined,affiliated:affiliated2?affiliated2:affiliated} });
        setCount(newdata.count)
        setTotal(newdata.count)
        if(PageP){
          SetPage(0);
        }
        if ((texto !== undefined && texto !== "") || defaultn || onlineM) {
          if ((texto !== undefined && texto !== "") || defaultn) {
            SetFilter({ total: true, online: onlineM ? onlineM : false })
          } else {
            SetFilter({ total: true, online: false })
          }
        } else {
          SetFilter({ online: false, total: false });
        }
        SetOnlineTotal(newdata.totalOnline)
        setHasMore(newdata.hasMore)
        let resultados = newdata.users.map((element: any) => {
          return {
            name: element.name,
            email: element.email,
            number: element.number,
            id: element.id,
            AddDate: element.createdAt,
            checked: false,
            ...element
          }
        })
        setData(a=>[...PageP?[]:a.map(e=>{
          const resultado = resultados.find((element:any)=>element.id == e.id);
          resultados=resultados.filter((element:any)=>element.id !== e.id)
          return {...e,...resultado}
        }),...resultados])
        setLoading(false)
        setIsloading(false)
      })()
    }
  }

  const loadMore = () => {
    SetPage((prevState) => prevState + 1);
  };

  const onScroll = useCallback((e: React.UIEvent) => {
    if (!hasMore || isLoading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }

  }, [hasMore, isLoading])

  useEffect(() => {
    if (pageReference?.current && (!pageReference?.current.onscroll || hasMore)) {
      pageReference.current.onscroll = (onScroll as any)
    }
  }, [onScroll, pageReference, hasMore])
  return (
    <>
      <s.Divider />
      <div>
        <header>
          <s.ListTotal>
            <li>Total: <span>{count}{count !== total && ` | ${total}`}</span></li>
          </s.ListTotal>

          <s.ButtonWrapper>
          <span onClick={async() => {
            try{
              setl?.(true)
const {data:all} = await api.get("/listfreetest", { params: { searchParam:text,role:role,status:status, all:true,plano:undefined } })
const newTable = XLSX.utils.book_new();
const datas = [
  [["Nome"],["Email"],["Telefone"],["Area De Atuação"],["Ip"],["Status"],["Data"],["Conectado?"]]
  ,...all.users.map((elment:any)=>{
  
  return[[elment.name],[elment.email],[elment.number],[elment.area],[elment.ip],[(new Date(elment.createdAt).getTime()+(86400000*3))>new Date().getTime()?"Ativo":"Inativo"],[elment?.createdAt?new Date(elment?.createdAt).toISOString().substring(0, 10):"Expirado"],[elment.status == "CONNECTED"?"Conectado":"Desconectado"]]

})]
const data = XLSX.utils.aoa_to_sheet(datas);
XLSX.utils.book_append_sheet(newTable,data,"Tabela");
const blob = new Blob([XLSX.write(newTable,{bookType:"xlsx",type:"buffer"})])
const link = document.createElement('a');
link.href = URL.createObjectURL(blob)
    link.setAttribute('download', 'myleadsbackup.xlsx')
    link.click()
setl?.(false)
toast.success("Successo")
}catch(a){
  console.log(a)
  toast.error("aconteceu algo errado")
  setl?.(false)
            }          
}}>
              <Button
                icon={<ExportIconBranco />}
                label='Baixar Planilha'
                primary={true}
                dropdownDisabled
              />
            </span>
            <Button
              icon={<FilterIcon />} label='Filtro'
              dropdownTitle='Filtro'
              dropdownSizeW='340px'
              dropdownSizeH='430px'
            >
              <Filter affiliated={affiliated} SetAffiliated={SetAffiliated} role={role} SetProfile={SetProfile} SetRole={SetRole} SetStatus={SetStatus} profile={profile} status={status} text={text} tab={tab} SetV2={SetV2} SetText={SetText} refresh={refresh} setDefaultN={setDefaultN} SetOnlineN={SetOnlineN} />
</Button>

            {/*<Button
                  icon={<ExportIcon />}
                  label='Exportar'
                  disabled={data.length >= 1 ? false : true}
                  dropdownDisabled
                >
                  Exportar
                </Button>

                <Button
                  icon={<ImportIcon />}
                  label='Importar'
                  dropdownTitle='Importar contatos'
                  dropdownSizeW='600px'
                  dropdownSizeH='500px'
                >
                  <ImportContacts />
                </Button>*/}
          </s.ButtonWrapper>
        </header>
      </div>

      <div>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            {data.length <= 0 ? (
              <s.EmptyDataContainer>
                <EmptyData />
                <h2>Você ainda não cadastrou nenhum membro</h2>
                <h3>Seus Membros só podem ser visualizados após serem adicionados.</h3>
              </s.EmptyDataContainer>
            ) : (
              <Table
                data={data}
                showDeleteModal={showDeleteModal}
                refresh={refresh}
                setData={setData}
                setShowDeleteModal={setShowDeleteModal}
                setSelectedContacts={setSelectedContacts}
              />
            )}
          </>
        )}
      </div>
      {addUser && (
        <Modal state={setAddUser}>
          <AddUser onCancel={() => setAddUser(false)} refresh={refresh} />
        </Modal>
      )}</>
  )
}