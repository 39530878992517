import React from "react";

function SucessUser() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <g clipPath="url(#clip0_597_16548)" filter="url(#filter0_d_597_16548)">
        <rect
          width="76"
          height="76"
          x="14"
          y="14"
          fill="#fff"
          rx="38"
          shapeRendering="crispEdges"
        ></rect>
        <rect
          width="75"
          height="75"
          x="14.5"
          y="14.5"
          stroke="#E5E9EE"
          rx="37.5"
          shapeRendering="crispEdges"
        ></rect>
        <path
          fill="#1A2633"
          fillRule="evenodd"
          d="M46 50a8 8 0 110-16 8 8 0 010 16zm19 0a1 1 0 01-1-1v-5h-5a1 1 0 01-1-1v-2a1 1 0 011-1h5v-5a1 1 0 011-1h2a1 1 0 011 1v5h5a1 1 0 011 1v2a1 1 0 01-1 1h-5v5a1 1 0 01-1 1h-2z"
          clipRule="evenodd"
          opacity="0.3"
        ></path>
        <path
          fill="#1A2633"
          d="M28.001 68.398C28.776 58.853 36.524 54 45.967 54c9.575 0 17.443 4.586 18.029 14.4.023.39 0 1.6-1.503 1.6H29.455c-.502 0-1.496-1.082-1.454-1.602z"
        ></path>
      </g>
      <rect
        width="103"
        height="103"
        x="0.5"
        y="0.5"
        stroke="#F7F8FA"
        rx="51.5"
      ></rect>
      <defs>
        <filter
          id="filter0_d_597_16548"
          width="106"
          height="106"
          x="-1"
          y="4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="5"
            result="effect1_dropShadow_597_16548"
          ></feMorphology>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.101961 0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0.05 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_597_16548"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_597_16548"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_597_16548">
          <rect width="104" height="104" fill="#fff" rx="52"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SucessUser;