import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// react router imports
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './routes';
import { AuthProvider } from './context/Auth/AuthContext'

// radix
import '@radix-ui/themes/styles.css';
import { Theme } from "@radix-ui/themes";

// theme
import { ThemeProvider } from "styled-components";
import { theme } from "./utils/theme";

// tooltip
import 'react-tooltip/dist/react-tooltip.css'

// toastfy
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// skeleton loading
import 'react-loading-skeleton/dist/skeleton.css'

// react phone number input
import 'react-phone-number-input/style.css'

// styles
import { GlobalStyle } from "./globalStyle";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Theme>
            <ToastContainer
              position='top-center'
              autoClose={2000} // time in ms
              closeOnClick
              pauseOnHover
              transition={Flip}
            />
            <AppRouter />
          </Theme>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();