// hooks
import { useState } from 'react';

// react-hook-forms
import { useForm } from 'react-hook-form'

// zod
import { SendEmailFormData, SendEmailFormSchema } from 'src/types/recovery-password-form'
import { zodResolver } from '@hookform/resolvers/zod'

// layout
import FormLayout from 'src/layout/Form';

// styles
import * as s from 'src/pages/Login/style'

// components
import { Header } from 'src/components/Header';
import Input from 'src/components/Input';
import { Submit } from 'src/components/Submit';

// utils
import { SendEmail } from 'src/utils/icons';
import api from 'src/services/api';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export const ForgotPassword = () => {
  const [submit, setSubmit] = useState<Boolean>(false)
  const {
    register,
    handleSubmit,
    formState: { errors }, // need to show the form validation errors
  } = useForm<SendEmailFormData>({
    resolver: zodResolver(SendEmailFormSchema)
  })

  const onSubmit = (data: SendEmailFormData) => {
    setSubmit(!submit);
    (async () => {
      const language = navigator.language.split("-")[0];
      try {
        await api.post("/recovery/", { ...data, language });
        toast.success("enviado com sucesso")
      } catch {
        toast.error("falha ao enviar email de recuperação tente daqui 5 minutos")
      }
    })()
  }

  return (
    <FormLayout>
      <s.Wrapper>
        {!submit ? (
          <Header
            title='Esqueceu a senha?'
            subTitle='Insira seu e-mail abaixo e enviaremos um e-mail para recuperação da sua senha.'
          />
        ) : (
          <Header
            image={<SendEmail />}
            title='Confira sua caixa de e-mail'
            subTitle='Enviaremos um email em alguns instantes.'
          />
        )}
        <s.Form onSubmit={handleSubmit(onSubmit)}>
          <s.InputContainer>
            {!submit && (
              <Input
                type='email'
                placeholder="Insira seu e-mail"
                register={register}
                errors={errors.email}
              >
                E-mail
              </Input>
            )}
          </s.InputContainer>
          <s.SubmitContainer>
            <Submit>
              {submit ? (
                <>
                  Reenviar e-mail
                </>
              ) : (
                <>
                  Enviar
                </>
              )}
            </Submit>
            <Link to='..' style={{
              cursor: 'pointer',
              color: '#445568'
            }}>Voltar e fazer login</Link>
          </s.SubmitContainer>
        </s.Form>
      </s.Wrapper>
    </FormLayout>
  )
}