import { styled } from "styled-components";

// react router dom
import { Link } from "react-router-dom";

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  gap: 50px;
  padding: 32px;

  @media (min-width: 1440px) {
    flex-direction: row;
    padding-left: 100px;
  }
`;

export const Wrapper = styled.div`
  width: 488px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  gap: 2rem;
`;

export const BonusWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: red;
  justify-content: space-between;
`;

export const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Span = styled.span`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.neutral500};
`;

export const ChangeRoute = styled(Link)`
  color: ${({ theme }) => theme.colors.neutral100};
  margin-left: 0.3rem;
`;
