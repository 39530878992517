// radix
import { Flex, Switch, Text } from "@radix-ui/themes"

// styles
import * as filter from './style'

// utils
import { Search } from "src/utils/icons"
import { useAuth } from "src/context/Auth/AuthContext"
import { useEffect, useState } from "react"
import Select from 'react-select'

export const Filter = ({ text, SetText, refresh, setDefaultN, SetOnlineN,affiliated, SetV2, tab,status,SetStatus,SetRole,role,SetAffiliated }: {affiliated?:string,SetAffiliated?:(string:string|undefined)=>void, SetStatus:(string:string|undefined)=>void,SetRole:(string:boolean|undefined)=>void,SetProfile: (bool: boolean | undefined) => void, status: string | undefined,role: boolean | undefined, profile: boolean | undefined, text: string, tab: string, SetV2?: (bool: boolean) => void, SetOnlineN?: (bool: boolean) => void, setDefaultN?: (bool: boolean) => void, SetText?: (text: string) => void, refresh?: (string?: string, defaultN?: boolean, OnlineN?: boolean, V2n?: boolean, PageP?: boolean,a?:boolean,b?:string,cs?:string) => void }) => {
  const { user } = useAuth()
  const [inputValue, setInputValue] = useState<string>(text)
  const [isOnline, setIsOnline] = useState<boolean>(
    () => {
      const storedValue = localStorage.getItem("isOnline");
      return storedValue !== null ? JSON.parse(storedValue) : false;
    }
  );

  const [isActived, setIsActived] = useState<boolean>(
    () => {
      const storedValue = localStorage.getItem("isActived");
      return storedValue !== null ? JSON.parse(storedValue) : false;
    }
  );

  useEffect(() => {
    localStorage.setItem("isOnline", JSON.stringify(isOnline));
    localStorage.setItem("isActived", JSON.stringify(isActived));
  }, [isOnline, isActived]);

  const toggleIsOnline = () => setIsOnline((prev) => !prev);
  const toggleIsActived = () => setIsActived((prev) => !prev);

  return (
    <filter.Container onSubmit={(event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);

      const formDataNovo = Object.fromEntries(formData.entries()) as unknown as { affiliated:string,role:string,status:string,nameOrNumber: string, isV2: boolean, online: boolean, default: boolean }
      SetText?.(formDataNovo.nameOrNumber);
      const isDefault = formDataNovo.default ? true : false;
      const isOnline = formDataNovo.online ? true : false
      const isV2 = formDataNovo.isV2 ? true : false;
      const role2 = formDataNovo.role? true : false;
      const status2 = formDataNovo.status;
      const affiliated2 = formDataNovo.affiliated;
      setDefaultN?.(isDefault);
      SetOnlineN?.(isOnline);
      SetAffiliated?.(affiliated2)
      SetRole(role2);
      SetStatus(status2)
      SetV2?.(isV2)
      refresh?.(formDataNovo.nameOrNumber, isDefault, isOnline, isV2,true,role2,status2,affiliated2);
    }}>
      <filter.InputWrapper>
        <label htmlFor="nameOrNumber">
          <Flex align='center' justify='center'>
            <Search />
          </Flex>
        </label>
        <input type="text" placeholder="Digite o nome ou número" id="nameOrNumber" name="nameOrNumber" value={inputValue} defaultValue={text} onChange={(e) => setInputValue(e.target.value)} />
      </filter.InputWrapper>
      {tab === 'backup' && (
        <Flex gap="2" justify='between'>
          Ativo
          <Switch size='3' color='gray' id="default" name="default" onClick={toggleIsActived} checked={isActived} />
        </Flex>
      )}
      <filter.SelectContainer>
            <label>
              Status
          <select name='status' defaultValue={status?status:"null"}>
                <option value="active">Ativo</option>
                <option value="expire">Expirado</option>
                <option value="null">Sem Filtro</option>
              </select>
            </label>
          </filter.SelectContainer>
          <filter.SelectContainer>
            <label>
              Conexão
          <select name='affiliated' defaultValue={affiliated?affiliated:"null"}>
                <option value="active">Conectado</option>
                <option value="expire">Desconectado</option>
                <option value="null">Sem Filtro</option>
              </select>
            </label>
          </filter.SelectContainer>
          <Text as="label" size="2">
            <Flex gap="2" justify='between'>
            Teste grátis não ativos
              <Switch size='3' color='gray' id="role" name="role"  defaultChecked={role?true:false} />
            </Flex>
          </Text>
      <filter.ButtonWrapper>
        <input type="submit" value="Aplicar" />
        <span onClick={() => {
          setInputValue('')
          SetText?.('')
          SetRole?.(undefined)
          SetStatus?.(undefined)
          SetAffiliated?.(undefined)
          refresh?.('', undefined, undefined, undefined,true,undefined,"null","null");
        }}>Limpar</span>
      </filter.ButtonWrapper>
    </filter.Container>
  )
}