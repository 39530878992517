import { z } from "zod";

// Schema -> data structure representation
export const RegisterFormSchema = z
  .object({
    name: z.string().nonempty("Você precisa digitar seu nome"),
    cellphone: z.string().nonempty("Você precisa digitar seu telefone"),
    email: z
      .string()
      .nonempty("Você precisa digitar seu e-mail")
      .email("E-mail inválido!"),
    password: z.string().nonempty("Você precisa digitar sua senha"),
    repeatPassword: z.string().nonempty("Você precisa repetir sua senha nova"),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: "As senhas precisam ser iguais",
    path: ["repeatPassword"],
  });

// type to remove typescript errors by reactHooks
export type RegisterFormData = z.infer<typeof RegisterFormSchema>;
