import React from "react";

function EmptyData() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <g clipPath="url(#clip0_597_16929)" filter="url(#filter0_d_597_16929)">
        <rect
          width="76"
          height="76"
          x="14"
          y="14"
          fill="#fff"
          rx="38"
          shapeRendering="crispEdges"
        ></rect>
        <rect
          width="75"
          height="75"
          x="14.5"
          y="14.5"
          stroke="#E5E9EE"
          rx="37.5"
          shapeRendering="crispEdges"
        ></rect>
        <path
          fill="#445568"
          fillRule="evenodd"
          d="M38 42a8 8 0 1016 0 8 8 0 00-16 0zm20 8a6 6 0 1012 0 6 6 0 00-12 0z"
          clipRule="evenodd"
          opacity="0.3"
        ></path>
        <path
          fill="#445568"
          fillRule="evenodd"
          d="M45.967 54C36.524 54 28.777 58.853 28 68.398c-.042.52.952 1.602 1.454 1.602h33.038c1.503 0 1.526-1.21 1.503-1.6-.586-9.814-8.454-14.4-18.03-14.4zm28.945 16H67.2c0-4.502-1.487-8.656-3.998-11.999 6.813.075 12.376 3.52 12.795 10.799.017.293 0 1.2-1.085 1.2z"
          clipRule="evenodd"
        ></path>
      </g>
      <rect
        width="103"
        height="103"
        x="0.5"
        y="0.5"
        stroke="#E5E9EE"
        rx="51.5"
      ></rect>
      <defs>
        <filter
          id="filter0_d_597_16929"
          width="106"
          height="106"
          x="-1"
          y="4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="5"
            result="effect1_dropShadow_597_16929"
          ></feMorphology>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.101961 0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0.05 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_597_16929"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_597_16929"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_597_16929">
          <rect width="104" height="104" fill="#fff" rx="52"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmptyData;