export const pt = {
  add: "Adicionar",
  no: "Não",
  yes: "Sim",
  exit: "Sair",
  admin: "Admin",
  name: "Nome",
  online: "Online",
  access: "Acessar",
  offline: "Offline",
  total: "Total",
  save: "Salvar",
  email: "Email",
  clean: "Limpar",
  filter: "Filtro",
  cancel: "Cancelar",
  contacts: "Contatos",
  number: "Número",
  status: "Status",
  members: "Membros",
  backups: "Backups",
  update: "Editar",
  select: "Selecionar",
  options: "Opções",
  exclude: "Excluir",
  default: "Padrão",
  producer: "Produtor",
  concluded: "Concluido",
  description: "Descrição",
  observation: "Observação",
  mainLayout: {
    components: {
      controls: {
        addContact: {
          numberUpdatedSuccessfully: "Número atualizado com sucesso",
          failToCreateNumber: "Falha ao criar número",
          yourNumberWasAddedSuccessfully: "Seu número foi adicionado com sucesso",
          insertContactNumber: "Insira o número do contato",
          insertDescriptionToYourNumber: "Insira uma descrição para seu número",
          yourContactWasAddedSuccessfully: "Seu contato foi adicionado com sucesso",
        },
        addMember: {
          memberUpdatedSuccessfully: "Membro atualizado com sucesso",
          failToUpdateMemberCheckYourEmail: "Falha ao atualizar membro, verifique se email está certo",
          emailAlreadyExists: "Email já existe...",
          youCantDoIt: "Você não tem permissão para isso",
          failToCommunicateServer: "Falha ao comunicar com servidor",
          memberCreatedSuccessfully: "Membro criado com sucesso",
          insertMemberEmail: "Insira o email do membro que deseja adicionar",
        },
        filter: {
          online: "Online",
          beta: "Beta",
          V2: "V2",
          active: "Ativado",
          expired: "Expirado",
          apply: "Aplicar",
          typeNameOrNumber: "Digite nome oou número",
        },
        importContacts: {
          anyImportWasFound: "Nenhuma importação foi encontrada",
          wasNoFoundImportCreateOne: "Não encontramos nenhuma importação realizada. Crie sua primeira importação!",
          position: "Posição",
          date: "Data",
          import: "Importar",
          newImport: "Nova importação",
        }
      },
      table: {
        unlockContact: "Desbloquear contato",
        deleteContact: "Deletar contato",
        editContact: "Editar contato",
        reallyWishToDeleteThisNumber: "Realmente deseja apagar esse número ?",
        numberCannotUse: "Número não poderá ser usado.",
        successToDelete: "Sucesso ao deletar",
        failToDelete: "Falha ao deletar",
      },
      tableMember: {
        contactNumber: "Telefone contato",
        whatsapp: "Whatsapp",
        includeDate: "Data de inclusão",
        notRegistered: "Nao cadastrado",
        editMember: "Editar membro",
        excludeMember: "Excluir membro",
        wishReallyToDelete: "Deseja realmente excluir ",
        thisMember: "Este membro",
        thoseMember: "Estes membros",
        the: "O",
        thePlural: "Os",
        member: "Membro",
        members: "Membros",
        cantBeUse: "Não poderá ser usado.",
      },
    },
  },
  layout: {
    main: {
      components: {
        header: {
          dropdown: {
            profileUpdatedSuccessfully: "Perfil atualizado com sucesso",
            failToEditProfile: "Falha ao atualizar perfil",
            editProfile: "Editar perfil",
            insertYourName: "Insira seu nome",
            insertYourPhone: "Insira seu número",
            wishToExitPanel: "Deseja sair do painel do cliente?",
            whatsappAlert: "É importante que o seu número de WhatsApp esteja cadastrado corretamente para que sua MyLeads funcione corretamente.",
          },
          plan: "Plano: ",
          valideAt: "Válido até: ",
          expiredLicenseRenew: "LICENÇA EXPIRADA, RENOVE SEU PLANO",
          hello: "Olá",
          additionalLicense: "Licença adicional: ",
        },
        sidebar: {
          registeredNumbers: "Números cadastrados",
          myNumbers: "Meus números",
          tolltipAdminPage: "Página do admin",
          tolltipProducerPage: "Página do produtor",
          install: "Instalar",
          installTolltip: "Instalar extensão Myleads",
          tutorial: "Tutorial",
          watchTutorials: "Assita aos tutoriais",
          speakSupport: "Falar com o suporte"
        }
      }
    }
  },
  pages: {
    admin: {
      clients: {
        membersArea: "Área de membros",
        defineMembers: "Você poderá definir quais membros estarão autorizados a usar seu backup na MyLeads Members.",
        youHaveNoRegisteredMember: "Você ainda não cadastrou nenhum membro",
        yourMembersOnlyShowWhenWasAdd: "Seus Membros só podem ser visualizados após serem adicionados.",
      }
    },
    clients: {
      howToAddNumberOnPanel: "Como adicionar número ao painel",
      learnHowToAddNumbersOnPanel: "Aprenda como adicionar número no seu painel",
      myLeadsLite: "MyBots (extensão)",
      whatsappNumbers: "Números de Whatsapp",
      allowNumbersToUse: "Você poderá definir quais números estarão autorizados a usar sua licença no MyBots (extensão).",
      howAddNumber: "Como adicionar número",
      youHaveNotRegisteredNumber: "Você ainda não cadastrou nenhum número",
      yourNumbersOnlySeeAfterAdd: "Seus Números só podem ser visualizados apos serem adicionados.",
      allContacts: "Todos os Contatos",
      hasAnyContact: "Não encontramos nenhum contato",
    },
    createPassword: {
      differentPasswords: "Senhas diferentes",
      passwordCreatedSuccessfully: "Senha criada com sucesso",
      failToCreatePassword: "Falha ao criar senha",
      createYourPassword: "Crie sua senha",
      nowCreateNewPassword: "Agora é só criar sua senha para uma nova. Sua senha precisa ser segura!",
      yourPasswordWasChangedSuccessfully: "Sua senha foi alterada",
      nowYouCanAccessYourAccount: "Agora você já pode acessar sua conta.",
      insertYourPassword: "Insira sua senha",
      confYourPassword: "Confirme sua senha",
      confPassword: "Confirmar senha",
      conclued: "Concluir",
      changePassword: "Alterar senha",
    },
    forgotPassword: {
      sentSuccessfully: "Enviado com sucesso",
      failToSentRecovery: "Falha ao enviar email de recuperação tente daqui 5 minutos",
      forgotPassword: "Esqueceu a senha?",
      insertYourEmailUnderRecovery: "Insira seu e-mail abaixo e enviaremos um e-mail para recuperação da sua senha.",
      checkYourBoxEmail: "Confira sua caixa de e-mail",
      sentEmailAFewMinutes: "Enviaremos um email em alguns instantes.",
      insertYourEmail: "Insira seu e-mail",
      resentEmail: "Reenviar email",
      backAndDoLogin: "Voltar e fazer login",
    },
    login: {
      accessYourAccount: "Acessar sua conta",
      insertYourEmailAndPassword: "Insira seu e-mail e senha para realizar o login.",
      insertEmail: "Inserir seu email",
      insertPassword: "Inserir sua senha",
      forgetPassword: "Esqueceu a senha ?",
      haveNoAccount: "Não tem conta?",
      register: "Cadastre-se"
    },
    manager: {
      myClients: "Meus clientes",
      whatsappNumbers: "Números de Whatsapp",
      totalActive: "Total ativo: ",
      totalCanceled: "Total cancelado: ",
    },
    register: {
      successToCreate: "Sucesso ao criar",
      failToRegister: "Falha ao registrar",
      createYourAcccount: "Crie sua conta",
      insertYourCorrectData: "Insira os dados corretamente para realizar o cadastro.",
      insertYourNumberAndLastNumber: "Insira seu nome e sobrenome",
      insertYourContactPhone: "Insira seu telefone de contato",
      firstNameAndLastName: "Primeiro nome e sobrenome",
      insertYourPassword: "Insira sua senha",
      confirYourPassword: "Confirme sua senha",
      createAccount: "Criar conta",
      wasAccount: "Já tem uma conta ?",
      doLogin: "Faça login",
    }
  },
  types: {
    loginForm: {
      youNeedTypeYourEmail: "Você precisa digitar seu e-mail",
      emailIsInvalid: "E-mail inválido!",
      youNeedTypeYourPassword: "Você precisa digitar sua senha",
    },
    recoveryPassword: {
      typeNewPassword: "Você precisa digitar sua nova senha",
      youNeedToRepeatYourNewPassword: "Você precisa repetir sua nova senha",
      samePasswords: "As senhas precisam ser iguais",
    },
    registerForm: {
      youNeedTypeYourEmail: "Você precisa digitar seu nome",
      typeYourPhone: "Você precisa digitar seu telefone",
      invalidEmail: "E-mail inválido!",
    }
  },
}