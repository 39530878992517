import { Tabs } from "@radix-ui/themes";
import styled from "styled-components";

export const Container = styled(Tabs.Root)`
  display: flex;
  height: 90%;
  flex-direction: column;

  @media (min-width: 55rem) {
    flex-direction: row;
  }
`;

export const TabsList = styled(Tabs.List)`
  height: auto;
  flex-direction: column;
  margin-top: 32px;
  box-shadow: none;
  gap: 0.5em;
`;

export const TabsTrigger = styled(Tabs.Trigger)`
  justify-content: left;
  padding: 5px 16px;
  border-radius: 50px;

  color: ${({ theme }) => theme.colors.neutral600};

  cursor: pointer;
  transition: 0.2s;

  &::before {
    top: 0;
    height: auto;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.neutral1000};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral1000};

    span {
      background-color: transparent;
    }
  }

  &[data-state="active"] {
    color: ${({ theme }) => theme.colors.neutral300};
  }
`;

export const Content = styled.div<{ $primary?: boolean }>`
  flex: ${(props) => (props.$primary ? 8 : 1)};
  padding: ${(props) => (props.$primary ? "1.5rem 0" : "0 0 1.5rem 0")};

  @media (min-width: 880px) {
    flex: ${(props) => (props.$primary ? 5 : 1)};
    flex-direction: row;
    padding: ${(props) =>
      props.$primary ? "0 1.5rem 1.5rem 1.5rem" : "0 1.5rem 0 0"};
    border-left: ${(props) => (props.$primary ? `0.125rem solid` : "0")};
    border-top: 0;
    border-color: ${({ theme }) => theme.colors.neutral900};
    max-height: 100%;
    overflow: auto;
  }

  h1 {
    font-weight: 500;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.neutral100};
  }

  h2 {
    font-weight: 400;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.neutral500};
    margin-top: 0.25rem;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2.5rem;
  }
`;

export const ListTotal = styled.ul`
  list-style: none;

  li {
    color: ${({ theme }) => theme.colors.neutral300};

    span {
      color: ${({ theme }) => theme.colors.neutral500};
    }
  }
`;

export const Divider = styled.hr`
  margin-top: 1.5rem;
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.neutral900};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: wrap;

  @media (min-width: 1304px) {
    flex-direction: row-reverse;
  }
`;

export const EmptyDataContainer = styled.div`
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  h2 {
    margin-top: 32px;
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral100};
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral500};
  }
`;
