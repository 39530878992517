// react-router
import { Route, Routes, Navigate } from 'react-router-dom'
// routes
import { Clients, Login, ForgotPassword, RecoveryPassword, Register } from './pages'
import { CreatePassword } from './pages/CreatePassword'
import { Fragment } from 'react';
import { Spinner } from './components/Spinner';
import { useAuth } from './context/Auth/AuthContext';
import { MainLayout } from 'src/layout/Main'
import { Admin } from './pages/Admin';
import { Members } from './pages/Members';
import { Manager } from './pages/Manager';
import Dashboard from './pages/add';
const routes =
  [
    {
      title: "404",
      path: "*",
      element: <Navigate to="/" replace={true} />,
      Verify: false
    },
    {
      title: "login",
      index: true,
      element: (<Login />),
      Verify: false
    },
    {
      title: "add",
      path: "add/:recoveryCode",
      index: true,
      element: (<Dashboard />),
      Verify: true
    },
    {
      title: "register",
      path: "register",
      element: (<Register />),
      Verify: false
    }, {
      title: "ForgotPassword",
      path: "forgot-password",
      element: (<ForgotPassword />),
      Verify: false
    }
    , {
      title: "recovery-password",
      path: "recovery-password/:id",
      element: (<RecoveryPassword />),
      Verify: false
    }, {
      title: "create-password",
      path: "create-password/:id",
      element: (<CreatePassword />),
      Verify: false
    },
    {
      title: "Minhas licenças",
      path: "clients",
      element: (<Clients />),
      Verify: true
    },
    {
      title: 'Admin',
      path: 'admin',
      role:"admin",
      element: (<Admin />),
      Verify: true
    },
    {
      title: 'Admin',
      path: 'admin/:page',
      role:"admin",
      element: (<Admin />),
      Verify: true
    },
    {
      title: 'Produtor',
      path: 'members',
      role:"productor",
      element: (<Members />),
      Verify: true
    },
    {
      title: 'Gerente',
      path: 'manager',
      role:"manager",
      element: (<Manager />),
      Verify: true
    },
  ]
const AppRouter = () => (
  <Routes>
    {routes.map((element,index) => <Route key={index} {...element} element={<ProtectedRoute role={element.role} title={element.title} Verify={element.Verify}>{element.element}</ProtectedRoute>} />)}
  </Routes>
)
const ProtectedRoute = ({ children, Verify, title,role }: {role?:string, children: React.ReactNode, Verify: boolean, title: string }) => {
  const { isAuth, user } = useAuth();
  // const isAuth = true
  if (!isAuth && Verify) {
    return <Navigate to="/" replace={true} />;
  }
  else if (Verify&&(!role || user?.profile=="admin"||user?.profile==role)) {
    return (
      <Fragment>
        <Spinner />
        <MainLayout title={title}>
          {children}
        </MainLayout>
      </Fragment>
    )
  }
  else if (isAuth && user) {
    return <Navigate to="/clients" replace={true} />;
  }
  else {
    return (
      <Fragment>
        <Spinner />
        {children}
      </Fragment>
    )
  }
};
export default AppRouter