// styled components
import * as s from './style'

// components
import Dropdown from './Dropdown'

// utils
import { userProfile } from 'src/utils/icons'
import { useAuth } from 'src/context/Auth/AuthContext'

// types
type HeaderProps = {
  path: string | null
}

export default function Header({ path }: HeaderProps) {
  const { user } = useAuth();

  return (
    <s.Header>
      <s.Route>{path}</s.Route>
      <s.Wrapper>
        <div>
          <Dropdown
            image={userProfile}
          >
            <s.UserContainer>
              <div>
                <s.Validate>
                  {user?.plano && user?.plan ? `Plano: ${user.plan.name} ${user.expire?`| Válido até: ${new Date(user.expire).toLocaleDateString()}`:" | "}` : "LICENÇA EXPIRADA, RENOVE SEU PLANO"}
                </s.Validate>
                <span>
                  Olá, <strong>{(user?.name[0].toUpperCase() || "S") + (user?.name.substring(1) || "em Nome")}!</strong>
                </span>
              </div>
            </s.UserContainer>
          </Dropdown>
          {user?.plano && user?.plan&&user.ExtraExpire&&user.ExtraExpire&&<s.Validate>Liçenca Adicional: {user.extras} | Válido até: {new Date(user.ExtraExpire).toLocaleDateString()}</s.Validate>}
        </div>
      </s.Wrapper>
    </s.Header >
  )
}