import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --toastify-color-progress-light: ${({ theme }) => theme.colors.neutral100};
    --PhoneInput-color--focus: #777 !important;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
  }

  /* React-phone-input */

  .PhoneInput {
    padding: 6px 0;

    input[type="tel"] {
      padding: 6px;
      border-radius: 6px;
      border: 1px solid #dedede;
    }
  }

  .PhoneInputCountry {
    background: #dedede;
    padding: 5px;
    border-radius: 6px;
  }

  .PhoneInput.PhoneInput--focus {
    outline: none;
    border: none;

    input[type="tel"] {
      padding: 6px;
      border-radius: 6px;
      border: 1px solid #dedede;
      outline: 1px solid #777;
    }
  }

  body {
    position: relative;
  }

  h1,
  h2,
  h3,
  h4 {
    text-wrap: balance;
  }

  p {
    text-wrap: pretty;
  }
`;
