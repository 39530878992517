import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const Reset = styled.input.attrs({ type: "reset" })`
  flex: 2;
  padding: 13px 16px;
  border-radius: 50px;

  background-color: ${({ theme }) => theme.colors.neutral900};
  color: ${({ theme }) => theme.colors.neutral100};

  border: 0;
  cursor: pointer;
`;

export const Submit = styled.input.attrs({ type: "submit" })`
  flex: 3;
  padding: 13px 16px;
  border-radius: 50px;

  background-color: ${({ theme }) => theme.colors.neutral100};
  color: ${({ theme }) => theme.colors.neutral1100};

  border: 0;
  cursor: pointer;
`;

export const InputContainer = styled.div<{ $hasError?: boolean }>`
  label {
    color: ${(props) => (props.$hasError ? "#445568" : "#738699")};
    font-weight: 500;
    font-size: 14px;
  }

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral300};
  }
`;

export const DateInput = styled.input.attrs({ type: "date" })`
  background-color: ${({ theme }) => theme.colors.neutral300};
  padding: 15px;
  font-family: "Roboto Mono", monospace;
  color: ${({ theme }) => theme.colors.neutral1000};
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;

  &::-webkit-calendar-picker-indicator {
    background-color: ${({ theme }) => theme.colors.neutral700};
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
  }
`;
