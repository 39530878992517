// props
import { ButtonHTMLAttributes, ReactNode } from "react";

// styles
import * as s from './style'

// types
type ButtonProps = {
  children?: ReactNode
}

export const Submit = (
  { children, ...props }: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <s.Button type="submit" {...props}>
      {children}
    </s.Button>
  )
}