import { ReactNode } from "react";
import { UseFormRegister, FieldError } from 'react-hook-form'

// styles
import * as input from './style'

// types
type InputProps = {
  type: string;
  register: UseFormRegister<any>;
  children: ReactNode;
  errors?: FieldError;
  placeholder: string;
  id?: string;
  defaultValue?: string;
  autocomplete?:string
}

const Input = ({autocomplete, type, register, children, errors, placeholder, id, defaultValue }: InputProps) => {
  return (
    <input.Container $hasError={errors ? true : false}>
      <label htmlFor={type}>
        {children}
      </label>
      <input
        {...autocomplete?{autoComplete:autocomplete}:{}}
        id={id || type}
        type={type} {...register(id || type as any)}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      {errors && <span>{errors.message}</span>}
    </input.Container>
  )
}

export default Input;