import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from "react"
import Close from "src/utils/icons/Close"

// style
import styled, { keyframes } from "styled-components"

const appears = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: fixed;
  inset: 0;
  background-color: #00000085;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: ${appears} .15s;
`

export const Card = styled.div`
  position: relative;
  width: 520px;
  background-color: white;
  border-radius: 20px;
  padding: 24px;
  animation: ${appears} .85s;
`

export const CloseContainer = styled.span`
  position: absolute;
  right: 32px;
  top: 32px;
  cursor: pointer;
`

type ModalProps = {
  children: ReactNode,
  state?: Dispatch<SetStateAction<boolean>>
}

export const Modal = ({ children, state }: ModalProps) => {
  return (
    <Container>
      <Card>
        <CloseContainer onClick={() => state?.(false)}>
          <Close />
        </CloseContainer>
        {children}
      </Card>
    </Container>
  )
}