export const theme = () => ({
  colors: {
    black: "#000000",
    neutral0: "#0F161E",
    neutral100: "#1A2633",
    neutral200: "#2E3D4D",
    neutral300: "#445568",
    neutral400: "#5B6D81",
    neutral500: "#738699",
    neutral600: "#92A1B1",
    neutral700: "#B3BFCC",
    neutral800: "#CCD4DD",
    neutral900: "#E5E9EE",
    neutral1000: "#F7F8FA",
    neutral1100: "#FFFFFF",
  },
});
