import styled from "styled-components";

export const Container = styled.div<{ $hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  font-size: 14px;
  font-weight: 400;

  label {
    color: ${(props) => (props.$hasError ? "#445568" : "#738699")};
    font-weight: 500;
  }

  input {
    border: 0;
    border-bottom: 1px solid
      ${(props) => (props.$hasError ? "#445568" : "#E5E9EE")};
    padding: 9px 0;
    color: #1a2633;

    outline: none;
    transition: 0.25s;

    &::placeholder {
      ${(props) => (props.$hasError ? "#1A2633" : "#92A1B1")}
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.neutral300};
    }
  }

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral300};
  }
`;
