import styled from "styled-components";

export const Button = styled.button`
  width: 100%;
  height: 3.5rem;
  background-color: ${({ theme }) => theme.colors.neutral100};

  border: 0;
  border-radius: 6.25rem;

  color: ${({ theme }) => theme.colors.neutral1100};
  font-weight: 600;
  font-size: 1rem;

  cursor: pointer;
`;
