import React, { useContext, createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/services/api';
import { toast } from 'react-toastify';
import { RegisterFormData } from 'src/types/register-form';
import openSocket from "../../services/socket-io";

interface Provider {
  children: React.ReactNode;
}
interface handleLoginProps {
  email:string,password:string
}
interface AuthProviderProps {
  user: User|undefined;
  handleLogin: ({email,password}:handleLoginProps)=>Promise<void>;
  handlerRegister: (userData:RegisterFormData)=>Promise<void>;
  handleLogout: ()=>Promise<void>;
  loading:boolean;
  setLoading:React.Dispatch<React.SetStateAction<boolean>>;
  SetUser:React.Dispatch<React.SetStateAction<User|undefined>>;
  isAuth:boolean;
}
interface User {
        id: 1,
        name: string,
        email: `${string}@${string}.${string}`,
        profile: "admin"|"user",
        saldo: number,
        expire: Date,
        ExtraExpire:Date,
        extras:number,
        number: number,
        plano?: number,
        plan?:{
          id: number;
          name:string;
          multidevice: number,
          numbers: number,
          price: number|null,
          createdAt: Date,
          updatedAt: Date
        }
  }
const Context = createContext<AuthProviderProps>({SetUser:()=>{},handleLogout:async()=>{},handlerRegister:async(userData)=>{}, isAuth:true,user: undefined,handleLogin:async(userData)=>{},loading:true,setLoading:()=>{} })

const useAuth = (): AuthProviderProps => useContext(Context);

const AuthProvider = ({ children }: Provider) => {
  const [user, setUser] = useState<User|undefined>(undefined);
  const [isAuth, setIsAuth] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useNavigate()
 async function handleLogin (userData:handleLoginProps){
  setLoading(true)
  try {
    const { data }:{data:{token:string,user:User}} = await api.post("/auth/login", userData);
    localStorage.setItem("token", JSON.stringify(data.token));
    api.defaults.headers.Authorization = `Bearer ${data.token}`;
    setUser(data.user);
    setIsAuth(true);
    toast.success("login bem sucesso");
    history("/clients");
    setLoading(false);
  } catch (err) {
    toast.error("algo deu errado");
    setLoading(false);
  }
  }
  const handleLogout = async () => {
		setLoading(true);

		try {
			await api.delete("/auth/logout");
			setIsAuth(false);
			setUser(undefined);
			localStorage.removeItem("token");
			api.defaults.headers.Authorization = ``;
			setLoading(false);
			history("/login");
		} catch (err) {
			toast.error("falha ao deslogar");
			setLoading(false);
		}
	};
  async function handlerRegister (userData:RegisterFormData){
    if(userData.password == userData.repeatPassword){
    setLoading(true)
    await api.post("/auth/signup", {...userData,number: userData?.cellphone.replace(/\D/g, "")});
    const { data }:{data:{token:string,user:User}} = await api.post("/auth/login", userData);
    localStorage.setItem("token", JSON.stringify(data.token));
    api.defaults.headers.Authorization = `Bearer ${data.token}`;
    setUser(data.user);
    setIsAuth(true);
    history("/clients");
    setLoading(false);
  }else{
   throw toast.error("as duas senhas são diferentes");
  }
    }
  useEffect(() => {
    const token = localStorage.getItem("token");
    if(!token){
      setIsAuth(false)
    }
    api.interceptors.request.use(
      config => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
          setIsAuth(true);
        }else{
          setIsAuth(false)
        }
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );
  
    api.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const originalRequest = error.config;
        if (error?.response?.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true;
  
          const { data } = await api.post("/auth/refresh_token");
          if (data) {
            localStorage.setItem("token", JSON.stringify(data.token));
            api.defaults.headers.Authorization = `Bearer ${data.token}`;
          }
          return api(originalRequest);
        }
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          (api.defaults.headers.Authorization as any) = undefined;
          setIsAuth(false);
        }
        return Promise.reject(error);
      }
    );
    (async () => {
			if (token) {
				try {
					const { data } = await api.post("/auth/refresh_token");
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
					setIsAuth(true);
					setUser(data.user);
				} catch (err) {
          localStorage.removeItem("token")
          setIsAuth(false)
					toast.error("algo deu errado");
				}
			}
			setLoading(false);
		})();
  }, [])
  useEffect(() => {
		const socket = openSocket();

		socket.on("user", data => {
			if (data.action === "update" && data.user.id === user?.id) {
			/*	if(data.user.saldo > user.saldo){
                toast.success(i18n.t("saldo") + `${parseFloat(data.user.saldo-user.saldo)}` + " MNC")
				}
				else if(data.user.saldo < user.saldo){
					toast.error(i18n.t("saldo") + `${parseFloat(data.user.saldo - user.saldo)*-1}` + " MNC")
					}*/
				setUser({...data.user,...data.user.planolindo?{plan:data.user.planolindo}:{plan:data.user.plan}});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [user]);

  return (
    <Context.Provider value={{handleLogout,handlerRegister,SetUser:setUser, user,handleLogin,loading,setLoading,isAuth}}>
      {children}
    </Context.Provider>
  )
}

export { useAuth, AuthProvider }