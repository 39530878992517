// hooks
import { useEffect, useState } from 'react'

// styles
import * as s from 'src/components/MainLayout/style'

// components
import { Button } from 'src/components/MainLayout/components/Button'
import { AddUser } from 'src/components/MainLayout/components/Controls/AddContact'
import { ImportContacts } from 'src/components/MainLayout/components/Controls/ImportContacts'
import { Filter } from 'src/components/MainLayout/components/Controls/Filter'
import { Table } from 'src/components/MainLayout/components/Table'
import { FilterCount } from 'src/components/FilterCount'
import { SkeletonTable } from 'src/components/MainLayout/components/Table/Skeleton'
import openSocket from "../../services/socket-io";
// radix
import { Flex, Switch, Tabs } from '@radix-ui/themes'
import * as styledClient from './style';
// utils
import {
  ExportIcon,
  FilterIcon,
  ImportIcon,
  PlusIcon,
  TrashCanIcon,
  // UserProfilePicture, TODO: only on PRO
  EmptyData,
  UnblockContacts,
  HowToUse,
} from 'src/utils/icons'

import { DataProps } from './data'
import api from 'src/services/api'
import { useAuth } from 'src/context/Auth/AuthContext'
import { Modal } from 'src/components/MainLayout/components/Table/Modal'
import { toast } from 'react-toastify'
import { Modal as ModalPage } from '../../components/Modal';
import { generateArrayPossibles } from 'src/utils/generateNumberArray'

export const Clients = () => {
  const [addUser, setAddUser] = useState<boolean>(false)
  const [data, setData] = useState<DataProps[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<DataProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true)
  const { user } = useAuth()
  const [count, setCount] = useState(0);
  const [defaultN, setDefaultN] = useState(false);
  const [online, SetOnline] = useState(0);
  const [text, SetText] = useState("");
  const [onlineN, SetOnlineN] = useState(false);
  const [page, SetPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [V2, SetV2] = useState(false);
  const [showAll,setShowAll] = useState(false);
  const [V1,setV1] = useState(false);
  const [isLoading, setIsloading] = useState(false)
  const [filter, SetFilter] = useState({ online: false, total: false });
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showHowToUse, setShowHowToUse] = useState(false);

  const bulkDelete = async () => {
    const contacts = selectedContacts.map(element => element.id);
    SetOnline?.(evt => evt - selectedContacts.filter(element => element.status == "online").length)
    try {
      selectedContacts.length > 0 && await api.post("/numberrs", {
        contacts
      });
      setData?.(data.filter(elmenet => !contacts.includes(elmenet.id)));
      setSelectedContacts([])
      toast.success("sucesso ao deletar")
    } catch {
      toast.error("falha ao deletar")
    }
  }
  useEffect(()=>{
const All = JSON.parse(localStorage.getItem("showAll") || "false");
if(All){
  setShowAll(All);
  refresh(undefined,undefined,undefined,undefined,true,All)
}
  },[])
  useEffect(() => {
    refresh()
  }, [user, page])
  function refresh(texto?: string, defaultn?: boolean, onlineM?: boolean, V2n?: boolean, pageP = false,showAll2?: boolean,V1n?:boolean) {
    if (user) {
      (async () => {
        if (page == 0) setLoading(true)
        setIsloading(true)
        if (isLoading) return;
        setSelectedContacts([])
        let { data: newdata } = await api.get("/numberrNovo", { params: { showAll:showAll2!== undefined?showAll2:showAll,texto: typeof texto == "string" ? texto : text, default: defaultn !== undefined ? defaultn : defaultN, online: onlineM !== undefined ? onlineM : onlineN, V2: V2n !== undefined ? V2n : V2,V1: V1n !== undefined ? V1n : V1, page: pageP ? 0 : page } });
        setCount(newdata.count)
        if ((texto !== undefined && texto !== "") || defaultn || onlineM) {
          if ((texto !== undefined && texto !== "") || defaultn) {
            SetFilter({ total: true, online: onlineM ? onlineM : false })
          } else {
            SetFilter({ total: true, online: false })
          }
        } else {
          SetFilter({ online: false, total: false });
        }
        if (pageP) SetPage(0);
        if ((onlineM !== undefined ? onlineM : onlineN) || pageP) { SetOnline(newdata.Online) } else {
          SetOnline(a => a + newdata.Online)
        }
        setHasMore(newdata.hasMore);
        let resultados = newdata.result.map((element: any) => {
          return {
            connections:element.conections,
            observation: element.numberrs.text, cellphone: element.numberrs.number, default: element.numberrs.default, status: element.status !==
              "DISCONNECTED" ? "online" : "offline", id: element.numberrs.id,
            checked: element.checked, beta: element.beta, isV2: element.isV2, name: element.name, email: element.email
          }
        })
        setData(a => {
          return [...pageP ? [] : a.map(element => {
            const ifHas = resultados.find((ele: any) => ele.id == element.id);
            if (ifHas) {
              resultados = resultados.filter(((ele: any) => ele.id !== element.id));
              return { ...element, ...resultados };
            } else {
              return element;
            }
          }), ...resultados]
        })
        setLoading(false)
        setIsloading(false)
      })()
    }
  }
  const searchFunc = (string: string, expected: string) => {
    return string.toLocaleLowerCase().search(expected.toLocaleLowerCase()) !== -1
  }
  useEffect(() => {
    const socket = openSocket();
    socket.on("connect", () => {
      socket.emit("joinCompany")
    });
    socket.on("Numberr", (SocketData: any) => {
      if (SocketData.action == "update") {
        if (SocketData.Numberr.Userid == user?.id || user?.profile == "admin") {
          const existe = data.find(element => SocketData.Numberr.id == element.id);
          const ifHasSearch = searchFunc(String(SocketData.Numberr.number), text) || searchFunc(SocketData?.Numberr?.user?.planolindo?.name || "", text) || searchFunc(SocketData.Numberr.user.name, text) || searchFunc(SocketData.Numberr.user.email, text) || searchFunc(SocketData.Numberr.user.status || "", text) || searchFunc(SocketData.Numberr.text, text)
          if (!ifHasSearch) return;
          if (existe) {
            const newData = data.map(element => {
              if (element.id == SocketData.Numberr.id) {
                return {
                  ...element,
                  cellphone: SocketData.Numberr.number,
                  default: SocketData.Numberr.default,
                  observation: SocketData.Numberr.text,
                  email: SocketData.Numberr.user.email,
                  name: SocketData.Numberr.user.name
                }
              } else {
                return element
              }
            })
            setData(newData);
          } else if (!onlineN && !V2 && !defaultN) {
            setData((a) => {
              return [...a, {
                cellphone: SocketData.Numberr.number,
                observation: SocketData.Numberr.text,
                email: SocketData.Numberr.user.email,
                name: SocketData.Numberr.user.name,
                default: SocketData.Numberr.number,
                status: "offline",
                checked: false,
                id: SocketData.Numberr.id
              }]
            })
            setCount(a=>a+1);
          }
          //refresh()
        }
      } else if (SocketData.action == "delete") {
        setData(data => data.filter(elmenet => {if(elmenet.id == SocketData.numberrid){
          setCount(a=>a-1)
          return false;
        }
      return true;
      }));
        ;
      } else if (SocketData.action == "BulkDelete") {
        const all = SocketData.data.map((element: any) => element);
        setData(data => data.filter(elmenet => {if(all.includes(elmenet.id)){
          setCount(a=>a-1)
return false;
        }
      return true
      }));
      } else if (SocketData.action == "online") {
        setData(data => data.map((elmenet: DataProps) => {
          if (generateArrayPossibles(String(SocketData.Numberr.number)).includes(String(elmenet.cellphone))) {
            return { ...elmenet, status: "online",connections:[...(elmenet?.connections||[]),SocketData.Numberr] }
          } else {
            return elmenet
          }
        }
        ));
      }
      else if (SocketData.action == "offline") {
        setData((data: any) => {
          const result = (data.map((elmenet: DataProps) => {
            if (generateArrayPossibles(String(SocketData.Numberr.number)).includes(String(elmenet.cellphone))) {
              return { ...elmenet, status: "offline" }
            } else {
              return elmenet
            }
          }
          ));
          return onlineN ? result.filter((element: any) => element.status !== "offline") : result
        });
      }
    })
    return () => {
      socket.disconnect();
    };
  }, [user, data, text])
  const loadMore = () => {
    SetPage((prevState) => prevState + 1);
  };
  const onScroll = (e: any) => {
    if (!hasMore || isLoading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  }
  return (
    <s.Container defaultValue='contacts'>
      {showHowToUse && (
        <ModalPage
          setModal={setShowHowToUse}
          title='Como adicionar número no painel'
          description='Aprenda como adicionar números no seu painel'
        >
          <iframe width="100%" height="auto" src="https://www.youtube.com/embed/X6D-aWSxzu4" title="Conheça o MyBots" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </ModalPage>
      )}
      <s.Content>
        <h1>Opções</h1>

        <s.TabsList>
          <s.TabsTrigger value="contacts">MyBots (Extensão)</s.TabsTrigger>
          {/*<s.TabsTrigger value="blocked-contacts">Contatos bloqueados</s.TabsTrigger>*/}
        </s.TabsList>
      </s.Content>

      <s.Content onScroll={onScroll} $primary={true}>
        <Tabs.Content value='contacts'>
          <h1>Números de Whatsapp</h1>
          <styledClient.FlexBox>
            <h2>Você poderá definirá quais números estarão autorizados a usar sua licença no MyBots (Extensão).</h2>
            <span onClick={() => setShowHowToUse(true)}>
              <Button
                buttonStyle={{ width: "225px" }}
                icon={<HowToUse style={{ filter: 'invert(1)' }} />}
                label='Como adicionar número'
                primary={true}
                dropdownDisabled
              />
            </span>
          </styledClient.FlexBox>
          <s.Divider />
          <div>
            <header>
              <s.ListTotal>
                <li>Total: <span>{count}/{(user?.extras || 0) + (user?.plan?.numbers || 0) + (isNaN(Number(user?.plan?.numbers)) ? 0 : 1)}</span></li>
                {user?.profile == "admin" && <li>Online: <span>{online}</span></li>}
              </s.ListTotal>

              <s.ButtonWrapper>
              {user?.profile == "admin"&&<Flex gap="2" justify='between'>
          Modo Admin
          <Switch size='3' color='gray' id="default" name="default" onClick={()=>{setShowAll((a:boolean)=>{
            refresh("",undefined,undefined,undefined,true,!a)
            localStorage.setItem("showAll",`${!a}`)
            return!a});}} checked={showAll} />
        </Flex>}
                <span onClick={() => setAddUser(true)}>
                  <Button
                    icon={<PlusIcon />}
                    label='Adicionar'
                    primary={true}
                    dropdownDisabled
                  // dropdownTitle='Adicionar contato'
                  // dropdownSizeW='340px'
                  // dropdownSizeH='500px'
                  />
                </span>


                <Button
                  icon={<FilterIcon />} label='Filtro'
                  dropdownTitle='Filtro'
                  dropdownSizeW={ document.documentElement.offsetWidth > 450 ? '340px' : '200px'}
                  dropdownSizeH='400px'
                >
                  <Filter setV1={setV1} V1={V1} isDefault={defaultN} IsV2={V2} isOnline={onlineN} text={text} SetV2={SetV2} SetText={SetText} refresh={refresh} setDefaultN={setDefaultN} SetOnlineN={SetOnlineN} />
                </Button>

                {/*<Button
                    icon={<ExportIcon />}
                    label='Exportar'
                    disabled={data.length >= 1 ? false : true}
                    dropdownDisabled
                  >
                    Exportar
                  </Button>

                  <Button
                    icon={<ImportIcon />}
                    label='Importar'
                    dropdownTitle='Importar contatos'
                    dropdownSizeW='600px'
                    dropdownSizeH='500px'
                  >
                    <ImportContacts />
                  </Button>*/}

                <Button
                  setShowDeleteModal={selectedContacts.length !== 0 ? setShowDeleteModal : undefined}
                  secondary={true}
                  icon={<TrashCanIcon />}
                  ghost={selectedContacts.length === 0 ? true : false}
                  disabled={selectedContacts.length !== 0 ? false : true}
                  label='Excluir'
                  dropdownDisabled={selectedContacts.length === 0 ? true : false}
                >
                  Excluir
                </Button>
              </s.ButtonWrapper>
            </header>
          </div>

          <div>
            {loading ? (
              <SkeletonTable />
            ) : (
              <>
                {data.length <= 0 ? (
                  <s.EmptyDataContainer>
                    <EmptyData />
                    <h2>Você ainda não cadastrou nenhum número</h2>
                    <h3>Seus Números só podem ser visualizados apos serem adicionados.</h3>
                  </s.EmptyDataContainer>
                ) : (
                  <Table
                    data={data}
                    showAll={showAll}
                    showDeleteModal={showDeleteModal}
                    bulkDelete={bulkDelete}
                    refresh={refresh}
                    SetOnline={SetOnline}
                    setCount={setCount}
                    setData={setData}
                    setShowDeleteModal={setShowDeleteModal}
                    setSelectedContacts={setSelectedContacts}
                  />
                )}
              </>
            )}
          </div>
        </Tabs.Content>

        <Tabs.Content value='blocked-contacts'>
          <h1>Contatos</h1>
          <h2>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h2>
          <s.Divider />
          <div>
            <header>
              <h1 style={{ fontSize: '18px' }}>Todos os Contatos</h1>

              <s.ButtonWrapper>
                <Button icon={<UnblockContacts />} primary={true} label='Desbloquear' dropdownDisabled={true} />

                <Button
                  icon={<FilterIcon />} label='Filtro'
                  dropdownTitle='Filtro'
                  dropdownSizeW={ document.documentElement.offsetWidth > 450 ? '340px' : '200px'}
                  dropdownSizeH='400px'
                >
                  <Filter setV1={setV1} V1={V1} isDefault={defaultN} IsV2={V2} isOnline={onlineN} text={text} SetV2={SetV2} SetText={SetText} refresh={refresh} setDefaultN={setDefaultN} SetOnlineN={SetOnlineN} />
                </Button>

                <Button icon={<TrashCanIcon />} ghost={true} label='Excluir' dropdownDisabled={true} />
              </s.ButtonWrapper>
            </header>
          </div>

          <div>
            {data.length <= 0 ? (
              <s.EmptyDataContainer>
                <EmptyData />
                <h2>Não encontramos nenhum contato</h2>
                <h3>Seus contatos serão visualizados após serem importados ou adicionados.</h3>
              </s.EmptyDataContainer>
            ) : (
              <Table showAll={showAll} setCount={setCount} bulkDelete={bulkDelete} refresh={refresh} data={data} SetOnline={SetOnline} blockedContactsTab setData={setData} setSelectedContacts={setSelectedContacts} />
            )}
          </div>
        </Tabs.Content>
      </s.Content>


      {
        addUser && (
          <Modal state={setAddUser}>
            <AddUser onCancel={() => setAddUser(false)} refresh={refresh} />
          </Modal>
        )
      }
    </s.Container >
  )
}