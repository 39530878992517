export const en = {
  add: "Add",
  no: "No",
  yes: "Yes",
  exit: "Exit",
  admin: "Admin",
  name: "Name",
  online: "Online",
  access: "Access",
  offline: "Offline",
  total: "Total",
  save: "Save",
  email: "Email",
  clean: "Clean",
  filter: "Filter",
  cancel: "Cancel",
  contacts: "Contacts",
  number: "Number",
  status: "Status",
  members: "Members",
  backups: "Backups",
  update: "Edit",
  select: "Select",
  options: "Options",
  exclude: "Exclude",
  default: "Default",
  producer: "Producer",
  concluded: "Concluded",
  description: "Description",
  observation: "Observation",
  mainLayout: {
    components: {
      controls: {
        addContact: {
          numberUpdatedSuccessfully: "Number updated successfully",
          failToCreateNumber: "Fail to create number",
          yourNumberWasAddedSuccessfully: "Your number was added successfully",
          insertContactNumber: "Insert contact number",
          insertDescriptionToYourNumber: "Insert description to your number",
          yourContactWasAddedSuccessfully: "Your contact was added successfully",
        },
        addMember: {
          memberUpdatedSuccessfully: "Member updated successfully",
          failToUpdateMemberCheckYourEmail: "Fail to update member, check your email",
          emailAlreadyExists: "Email already exists...",
          youCantDoIt: "You can't do it",
          failToCommunicateServer: "Fail to communicate with server",
          memberCreatedSuccessfully: "Member created successfully",
          insertMemberEmail: "Insert the email of the member you want to add",
        },
        filter: {
          online: "Online",
          beta: "Beta",
          V2: "V2",
          active: "Active",
          expired: "Expired",
          apply: "Apply",
          typeNameOrNumber: "Type name or number",
        },
        importContacts: {
          anyImportWasFound: "No import was found",
          wasNoFoundImportCreateOne: "We didn't find any import performed. Create your first import!",
          position: "Position",
          date: "Date",
          import: "Import",
          newImport: "New import",
        }
      },
      table: {
        unlockContact: "Unlock contact",
        deleteContact: "Delete contact",
        editContact: "Edit contact",
        reallyWishToDeleteThisNumber: "Do you really wish to delete this number?",
        numberCannotUse: "Number cannot be used.",
        successToDelete: "Success to delete",
        failToDelete: "Fail to delete",
      },
      tableMember: {
        contactNumber: "Contact number",
        whatsapp: "Whatsapp",
        includeDate: "Inclusion date",
        notRegistered: "Not registered",
        editMember: "Edit member",
        excludeMember: "Exclude member",
        wishReallyToDelete: "Do you really wish to delete",
        thisMember: "This member",
        thoseMember: "These members",
        the: "The",
        thePlural: "The",
        member: "Member",
        members: "Members",
        cantBeUse: "Cannot be used.",
      },
    },
  },
  layout: {
    main: {
      components: {
        header: {
          dropdown: {
            profileUpdatedSuccessfully: "Profile updated successfully",
            failToEditProfile: "Fail to edit profile",
            editProfile: "Edit profile",
            insertYourName: "Insert your name",
            insertYourPhone: "Insert your phone",
            wishToExitPanel: "Do you wish to exit the client panel?",
            whatsappAlert: "It's important that your WhatsApp number is correctly registered for your MyLeads to work properly.",
          },
          plan: "Plan: ",
          valideAt: "Valid until: ",
          expiredLicenseRenew: "EXPIRED LICENSE, RENEW YOUR PLAN",
          hello: "Hello",
          additionalLicense: "Additional license: ",
        },
        sidebar: {
          registeredNumbers: "Registered numbers",
          myNumbers: "My numbers",
          tolltipAdminPage: "Admin page",
          tolltipProducerPage: "Producer page",
          install: "Install",
          installTolltip: "Install Myleads extension",
          tutorial: "Tutorial",
          watchTutorials: "Watch tutorials",
          speakSupport: "Speak with support"
        }
      }
    }
  },
  pages: {
    admin: {
      clients: {
        membersArea: "Members area",
        defineMembers: "You can define which members will be authorized to use your backup on MyLeads Members.",
        youHaveNoRegisteredMember: "You haven't registered any member yet",
        yourMembersOnlyShowWhenWasAdd: "Your Members can only be viewed after being added.",
      }
    },
    clients: {
      howToAddNumberOnPanel: "How to add number on panel",
      learnHowToAddNumbersOnPanel: "Learn how to add number on your panel",
      myLeadsLite: "MyBots (extension)",
      whatsappNumbers: "Whatsapp numbers",
      allowNumbersToUse: "You can define which numbers will be authorized to use your license on MyBots (extension).",
      howAddNumber: "How to add number",
      youHaveNotRegisteredNumber: "You haven't registered any number yet",
      yourNumbersOnlySeeAfterAdd: "Your Numbers can only be viewed after being added.",
      allContacts: "All Contacts",
      hasAnyContact: "We haven't found any contact",
    },
    createPassword: {
      differentPasswords: "Different passwords",
      passwordCreatedSuccessfully: "Password created successfully",
      failToCreatePassword: "Fail to create password",
      createYourPassword: "Create your password",
      nowCreateNewPassword: "Now just create your password for a new one. Your password needs to be secure!",
      yourPasswordWasChangedSuccessfully: "Your password was changed",
      nowYouCanAccessYourAccount: "Now you can access your account.",
      insertYourPassword: "Insert your password",
      confYourPassword: "Confirm your password",
      confPassword: "Confirm password",
      conclued: "Conclude",
      changePassword: "Change password",
    },
    forgotPassword: {
      sentSuccessfully: "Sent successfully",
      failToSentRecovery: "Fail to send recovery email, try again in 5 minutes",
      forgotPassword: "Forgot password?",
      insertYourEmailUnderRecovery: "Insert your email below and we'll send an email to recover your password.",
      checkYourBoxEmail: "Check your email box",
      sentEmailAFewMinutes: "We'll send an email in a few moments.",
      insertYourEmail: "Insert your email",
      resentEmail: "Resend email",
      backAndDoLogin: "Back and do login",
    },
    login: {
      accessYourAccount: "Access your account",
      insertYourEmailAndPassword: "Insert your email and password to login.",
      insertEmail: "Insert your email",
      insertPassword: "Insert your password",
      forgetPassword: "Forget password?",
      haveNoAccount: "Don't have an account?",
      register: "Register"
    },
    manager: {
      myClients: "My clients",
      whatsappNumbers: "Whatsapp numbers",
      totalActive: "Total active: ",
      totalCanceled: "Total canceled: ",
    },
    register: {
      successToCreate: "Success to create",
      failToRegister: "Fail to register",
      createYourAcccount: "Create your account",
      insertYourCorrectData: "Insert the correct data to register.",
      insertYourNumberAndLastNumber: "Insert your first and last name",
      insertYourContactPhone: "Insert your contact phone",
      firstNameAndLastName: "First name and last name",
      insertYourPassword: "Insert your password",
      confirYourPassword: "Confirm your password",
      createAccount: "Create account",
      wasAccount: "Already have an account?",
      doLogin: "Do login",
    }
  },
  types: {
    loginForm: {
      youNeedTypeYourEmail: "You need to type your email",
      emailIsInvalid: "Invalid email!",
      youNeedTypeYourPassword: "You need to type your password",
    },
    recoveryPassword: {
      typeNewPassword: "You need to type your new password",
      youNeedToRepeatYourNewPassword: "You need to repeat your new password",
      samePasswords: "Passwords need to be the same",
    },
    registerForm: {
      youNeedTypeYourEmail: "You need to type your name",
      typeYourPhone: "You need to type your phone",
      invalidEmail: "Invalid email!",
    }
  },
}
