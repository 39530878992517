import styled from "styled-components";

export const Button = styled.button<{
  $primary?: boolean;
  $isDisabled?: boolean;
  $ghost?: boolean;
  $secondary?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 100%;
  height: 40px;
  padding: 13px 24px;

  border: 0;
  border-radius: 50px;

  background-color: ${({ theme }) => theme.colors.neutral900};
  color: ${({ theme }) => theme.colors.neutral100};

  cursor: pointer;
  transition: filter 0.25s;

  ${(props) =>
    props.$primary && {
      backgroundColor: "#1A2633",
      color: "#FFFFFF",
    }};
  ${(props) =>
    props.$secondary && {
      color: "#FFFFFF",
      backgroundColor:"#ff0000"
    }};
  ${(props) =>
    props.$isDisabled && {
      opacity: "40%",
      cursor: "not-allowed",
    }};

  ${(props) =>
    props.$ghost && {
      background: "transparent",
      color: "#738699",
    }};

  &:hover {
    filter: brightness(0.85);
  }

  @media (min-width: 333px) {
    width: 130px;
  }
`;

export const Dropdown = styled.div<{ $sizeW?: string; $sizeH?: string }>`
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;

  width: ${(props) => props.$sizeW};
  height: ${(props) => props.$sizeH};

  border-radius: 20px;
  z-index: 1;

  background-color: ${({ theme }) => theme.colors.neutral1100};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  overflow: auto;

  @media (min-width: 450px) {
    left: unset
  }
`;

export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral900};

  p {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.neutral100};
  }

  span {
    cursor: pointer;
  }
`;
