export const generateArrayPossibles = (number:string|string[]):string[]=>{
    const stringNumber = String(number);
    const pais = stringNumber.slice(0, 2);
    if(typeof number == "object")return number;
    if(pais == "55"){
       const estado = stringNumber[2]+stringNumber[3];
       const numero  =number.slice(-8);
       const numeroErrado  =number.slice(-7);
       const ddquebrado = number.split("").reverse()[7]
       return [pais+estado+numero,pais+estado+"9"+numero,pais+estado+ddquebrado+"9"+numeroErrado] 
    }else{
        return [number];
    }
    }