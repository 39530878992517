import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { Switch } from "@radix-ui/themes"
// zod
import { AddContactFormData, AddContactFormSchema } from 'src/types/add-notification-form'
import { zodResolver } from '@hookform/resolvers/zod'

// rect select
import Select from 'react-select'

// components
import Input from 'src/components/Input'
import { AlertDropdown } from '../../AlertDropdown'

// styles
import * as s from './style'

// utils
import SucessUser from 'src/utils/icons/SucessUser'
import api from 'src/services/api'
import { toast } from 'react-toastify'
import { DataProps } from 'src/pages/Admin/data'
import { Flex, Text } from '@radix-ui/themes'

interface NotificationForm extends AddContactFormData {
  highlight: boolean;
}

interface AddNotifications {
  editedContact?: DataProps & Partial<Pick<NotificationForm, 'highlight'>>,
  onCancel?: () => void,
  refresh?: (a?: string, b?: boolean, c?: boolean, d?: boolean, e?: boolean) => void
}

export const AddUser = ({ onCancel, refresh, editedContact }: AddNotifications) => {
  const [displayAlert, setDisplayAlert] = useState<boolean>(false)
  const [version, setVersion] = useState<String>(editedContact?.Version || "null");
  const [extra, setExtra] = useState<String>(editedContact?.Extra ? JSON.stringify(editedContact?.Extra) : "null");
  const [plano, setPlano] = useState<any[]>(editedContact?.Plano ? JSON.parse(editedContact?.Plano) : []);
  const [role, setRoles] = useState<any[]>(editedContact?.Role ? JSON.parse(editedContact?.Role) : []);
  const [buttons, setButtons] = useState<any[]>(editedContact?.Buttons ? JSON.parse(editedContact?.Buttons) : []);
  const [ExtraExpire, setExtraExpire] = useState<Date | undefined>(editedContact?.ExtraExpire || undefined);
  //const [expire, setExpire] = useState<Date | undefined>(editedContact?.expire || undefined);
  const [highlight, setHighlight] = useState<boolean>(editedContact?.highlight||false);
  // const [hotmartnosend, setHotmartnosend] = useState<boolean>(editedContact?.nohotmart || false);
  const [OpenNotificationModal, setOpenNotificationModal] = useState<boolean>(editedContact?.OpenNotificationModal || false)
  const {
    register,
    handleSubmit,
    formState: { errors }, // need to show the form validation errors
  } = useForm<NotificationForm>({
    resolver: zodResolver(AddContactFormSchema)
  })
  const [planos, setPlanos] = useState([])

  useEffect(() => {
    (async () => {
      const { data: plan } = await api.get("/plano");
      setPlanos(plan.map((element: any) => {
        return { value: element.id, label: element.name }
      }));
    })()
  }, [])

  const onSubmit = async (data: AddContactFormData) => {
    const newData = {
      ...data,
      Expire: ExtraExpire,
      Version: version,
      Plano: plano,
      Extra: extra,
      highlight,
      OpenNotificationModal,
      Role: role,
      Buttons: buttons.map((element, index) => {
        return {
          title: (data as any)['button-title' + index],
          link: (data as any)['button-link' + index]
        }
      }).map(a => {
        return { ...a, link: a.link.search("https://") === -1 ? "https://" + a.link : a.link }
      })
    }
    if (!displayAlert) {
      if (editedContact) {
        try {
          await api.put("/notification/" + editedContact?.id, {
            ...editedContact, ...newData
          });
          toast.success("notificacao atualizado com sucesso");
          refresh?.(undefined, undefined, undefined, undefined, true)
        } catch (err: any) {
          if (err.response.status === 403) {
            if (err.response.data.error === "ERR_NO_PERMISSION") {
              toast.error("Você não tem permissão para isso");
            }
            return;
          }
          toast.error("falha ao se comunicar com servidor")
        }
      }
      else {
        try {
          await api.post("/notification", {
            ...newData
          });
          toast.success("SUA NOTIFICACAO FOI ENVIADA!");
          refresh?.()
        } catch (err: any) {
          if (err.response.status === 403) {
            if (err.response.data.error === "ERR_USER_INVALID") {
              toast.error("falha ao adicionar membro verifique se email está certo");
            } else if (err.response.data.error === "ERR_USER_UNNEW") {
              toast.error("email ja existe...");
            } else if (err.response.data.error === "ERR_NO_PERMISSION") {
              toast.error("Você não tem permissão para isso");
            }
            return;
          }
          toast.error("falha ao se comunicar com servidor")
        }
      }
      return setDisplayAlert(true)
    }

    // close modal
    onCancel?.()
  }

  const handleIncreseButton = (e: any) => {
    e.preventDefault();
    setButtons((f) => [...f, { title: "", link: "" }])
  }
  const handleRemoveButton = (e: any, index: number) => {
    e.preventDefault();
    setButtons((f) => f.filter((a, b) => b !== index));
  }
  const options = [{ value: "V1", label: "V1" }, { value: "V2", label: "V2" }, { value: "null", label: "Sem Filtro" }]
  const ExtraOptions = [{ value: "true", label: "Com extra" }, { value: "false", label: "Sem Extra" }, { value: "null", label: "Sem Filtro" }]
  const roles = [{ value: "user", label: "Usuario" }, { value: "manager", label: "Gerente" }, { value: "productor", label: "Produtor" }, { value: "admin", label: "Admin" }]

  return (
    <s.Form onSubmit={handleSubmit(onSubmit)}>
      {displayAlert ? (
        <AlertDropdown icon={<SucessUser />} title='SUA NOTIFICACAO FOI ENVIADA!' />
      ) : (
        <div style={{ overflow: "auto", maxHeight: "35em" }}>
          <Input
            type='name'
            placeholder='Insira o Titulo da Notificação que deseja adicionar'
            register={register}
            errors={errors.name}
            defaultValue={editedContact?.name}
          >
            Titulo
          </Input>
          <Input
            type='namees'
            placeholder='Insira o Titulo da Notificação que deseja adicionar'
            register={register}
            errors={errors.namees}
            defaultValue={editedContact?.namees}
          >
            Titulo espanhol
          </Input>
          <Input
            type='nameen'
            placeholder='Insira o Titulo da Notificação que deseja adicionar'
            register={register}
            errors={errors.nameen}
            defaultValue={editedContact?.nameen}
          >
            Titulo ingles
          </Input>
          <Input
            type='Textes'
            placeholder='Insira o Titulo da Notificação que deseja adicionar'
            register={register}
            errors={errors.Textes}
            defaultValue={editedContact?.Textes}
          >
            Texto da Notificação espanhol
          </Input>
          <Input
            type='Texten'
            placeholder='Insira o Titulo da Notificação que deseja adicionar'
            register={register}
            errors={errors.Texten}
            defaultValue={editedContact?.Texten}
          >
            Texto da Notificação ingles
          </Input>
          <Input
            type='Text'
            placeholder='Insira o Titulo da Notificação que deseja adicionar'
            register={register}
            errors={errors.Text}
            defaultValue={editedContact?.Text}
          >
            Texto da Notificação
          </Input>
          <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>Versão</label>
          <Select
            options={options}
            // isMulti
            onChange={(e) => e && e?.value && setVersion(e?.value)}
            defaultValue={options.find(element => element.value === editedContact?.Version) || { value: "null", label: "Sem Filtro" }}
            isSearchable={false}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                '&:hover': { borderColor: '#E5E9EE' },
                borderColor: state.isFocused ? '#B3BFCC' : '#E5E9EE',
                boxShadow: state.isFocused ? '0 0 1px 2px #B3BFCC' : 'none'
              })
            }}
          />
          <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>Planos</label>
          <Select
            options={planos}
            onChange={(e) => e && setPlano([...e])}
            defaultValue={editedContact?.Plano ? JSON.parse(editedContact?.Plano) : []}
            isMulti
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                '&:hover': { borderColor: '#E5E9EE' },
                borderColor: state.isFocused ? '#B3BFCC' : '#E5E9EE',
                boxShadow: state.isFocused ? '0 0 1px 2px #B3BFCC' : 'none'
              })
            }}
          />
          <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>Cargos</label>
          <Select
            options={roles}
            onChange={(e) => e && setRoles([...e])}
            defaultValue={editedContact?.Role ? JSON.parse(editedContact?.Role) : []}
            isMulti
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                '&:hover': { borderColor: '#E5E9EE' },
                borderColor: state.isFocused ? '#B3BFCC' : '#E5E9EE',
                boxShadow: state.isFocused ? '0 0 1px 2px #B3BFCC' : 'none'
              })
            }}
          />
          <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>extra</label>
          <Select
            options={ExtraOptions}
            // isMulti
            onChange={(e) => e && e?.value && setExtra(e?.value)}
            defaultValue={ExtraOptions.find(element => element.value === JSON.stringify(typeof editedContact?.Extra == "boolean" ? editedContact?.Extra : null))}
            isSearchable={false}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                '&:hover': { borderColor: '#E5E9EE' },
                borderColor: state.isFocused ? '#B3BFCC' : '#E5E9EE',
                boxShadow: state.isFocused ? '0 0 1px 2px #B3BFCC' : 'none'
              })
            }}
          />
          <Text as="label" size="2">
            <Flex gap="2" justify='between' style={{ marginTop: "1em" }}>
              Abrir aba de Notificação
              <Switch size='3' onCheckedChange={setOpenNotificationModal} color='gray' id="OpenNotificationModal" name="OpenNotificationModal" defaultChecked={editedContact?.OpenNotificationModal ? true : false} />
            </Flex>
          </Text>
          <Text as="label" size="2">
            <Flex gap="2" justify='between' style={{ marginTop: "1em", marginBottom: "1em" }}>
              Destacar notificação
              <Switch size='3' onCheckedChange={setHighlight} color='gray' id="highlight" name="highlight" defaultChecked={editedContact?.highlight ? true : false} />
            </Flex>
          </Text>
          {buttons.map((button, index) => (
            <React.Fragment>
              <s.Submit type="submit" value='X' onClick={(e) => handleRemoveButton(e, index)} style={{ marginTop: "1em" }} />
              <Input
                type={'button-title' + index}
                placeholder='Insira o Titulo dos botão que deseja adicionar'
                register={register}
                defaultValue={button.title}
              >
                Titulo do botão {index + 1}
              </Input>
              <Input
                type={'button-link' + index}
                placeholder='Insira o Link da Botão que deseja adicionar'
                register={register}
                defaultValue={button.url}
              >
                Link do botão {index + 1}
              </Input>
            </React.Fragment>
          ))}
          {buttons.length <= 2 && <s.Submit type="submit" value='Adicionar opção' onClick={(e) => handleIncreseButton(e)} />}
          <Flex direction='column' gap='2' style={{ marginTop: "1em" }}>
            <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>Expira</label>
            <s.DateInput type="date" onChange={(e) => setExtraExpire(new Date(e.target.value))} defaultValue={editedContact?.AddDate && new Date(new Date(editedContact.AddDate).getTime() + (86400000 * 7)).toISOString().substring(0, 10)} />
          </Flex>
          <s.SubmitWrapper style={{ marginTop: "2em" }}>
            <s.Reset type="reset" value="Cancelar" onClick={() => { onCancel?.() }} />
            <s.Submit type="submit" value={editedContact ? "Editar" : "Adicionar"} />
          </s.SubmitWrapper>
        </div>
      )}
    </s.Form>
  )
}