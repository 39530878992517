import  { useContext,useEffect,useState } from "react"
import { Link as RouterLink ,useNavigate,useParams} from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify"
import { useAuth } from "src/context/Auth/AuthContext";


const Dashboard = () => {
    const { recoveryCode } = useParams();
    const history = useNavigate();
    const {user} = useAuth();
	const [loading, setLoading] = useState(false);
    useEffect(()=>{
        if(user){
       (async ()=>{ try{
await api.post("/numberr",{    number: recoveryCode,
text: "",
default: false,})
toast.success("Numero Adicionado com successo")
setTimeout(async() => {history("/")},500);
        } catch (err) {
			toast.error("falha ao adiconar numero")
            setTimeout(async() => {history("/")},500);
        }})()}
    },[user])
	return (
		<div>
			     <h1>Confirmando seu numero aguarde</h1>
		</div>
	)
}

export default Dashboard