// styled-components
import * as s from './style'

// utils
import { FullLogo } from 'src/utils/icons'

// types
interface IHeader {
  title: string,
  subTitle: string,
  image?: any
}

export const Header = ({ title, subTitle, image }: IHeader) => {
  return (
    <s.Container>
      <s.Logo>
        <FullLogo />
      </s.Logo>
      {image && (
        <s.Icon>
          {image}
        </s.Icon>
      )}
      <div>
        <s.Title>{title}</s.Title>
        <s.SubTitle>{subTitle}</s.SubTitle>
      </div>
    </s.Container>
  )
}