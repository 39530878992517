import { z } from "zod";

// Schema -> data structure representation
export const AddContactFormSchema = z.object({
//  cellphone: z.string().nonempty("Digite o número de telefone do seu contato"),
numbers: z.string().nonempty("Insira a quantidade de Numeros do plano"),
multidevice: z.string().nonempty("Insira a quantidade de Sessões do plano"),
name:z.string().nonempty("Nome do plano")
//  defaultContact: z.boolean(),
});

// type to remove typescript errors by reactHooks
export type AddContactFormData = z.infer<typeof AddContactFormSchema>;
