import React from 'react';
import * as s from './style';

interface ModalProvider {
  title: string;
  description: string;
  children: React.ReactNode;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Modal = ({ children, description, title, setModal }: ModalProvider) => {

  const closeModal = () => setModal(false);

  return (
    <s.ModalPage>
      <s.Exit onClick={closeModal}>X</s.Exit>
      <div className='body--modal'>
        <s.Title>{title}</s.Title>
        <s.Description>{description}</s.Description>
        <br />
        {children}
      </div>
    </s.ModalPage>
  )
}