import React from "react";

function HorizontalControl() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="4"
      fill="none"
      viewBox="0 0 16 4"
    >
      <path
        stroke="#738699"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13 2a1 1 0 102 0 1 1 0 00-2 0zM7 2a1 1 0 102 0 1 1 0 00-2 0zM1 2a1 1 0 102 0 1 1 0 00-2 0z"
      ></path>
    </svg>
  );
}

export default HorizontalControl;