import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 32px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.colors.neutral900};
  padding: 12px 16px;

  input[type="text"] {
    width: 100%;
    border: 0;
    outline: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  input[type="submit"] {
    width: 100%;
    padding: 13px 16px;
    border-radius: 50px;

    background-color: ${({ theme }) => theme.colors.neutral100};
    color: ${({ theme }) => theme.colors.neutral1100};

    border: 0;
    cursor: pointer;
  }

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral500};
    cursor: pointer;
  }
`;

export const SelectContainer = styled.div<{ $hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  width: 100%;

  font-size: 0.875rem;
  font-weight: 400;

  label {
    color: ${(props) => (props.$hasError ? "#445568" : "#738699")};
    font-weight: 500;
  }

  select {
    width: 100%;
    border: 0;
    padding: 0.5625rem 0;
    border-bottom: 0.0625rem solid
      ${(props) => (props.$hasError ? "#1A2633" : "#E5E9EE")};
    color: ${(props) => (props.$hasError ? "#445568" : "#92A1B1")};
    outline: none;
  }

  option {
    padding: 1rem;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  span {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.neutral300};
  }
`;
