// radix
import { Flex, Switch, Text } from "@radix-ui/themes"

// styles
import * as filter from './style'

// utils
import { Search } from "src/utils/icons"
import { useAuth } from "src/context/Auth/AuthContext"
import { useEffect, useState } from "react"

export const Filter = ({ text,SetText, refresh, setDefaultN,defaultN, SetOnlineN, SetV2,onlineN,V2, tab }: {V2:boolean,onlineN:boolean, defaultN:boolean,text:string,tab: string, SetV2?: (bool: boolean) => void, SetOnlineN?: (bool: boolean) => void, setDefaultN?: (bool: boolean) => void, SetText?: (text: string) => void, refresh?: (string?: string, defaultN?: boolean, OnlineN?: boolean, V2n?: boolean,PageP?:boolean) => void }) => {
  const { user } = useAuth()
  const [inputValue, setInputValue] = useState<string>(text)




  return (
    <filter.Container onSubmit={(event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);

      const formDataNovo = Object.fromEntries(formData.entries()) as unknown as { nameOrNumber: string, isV2: boolean, online: boolean, default: boolean }
      SetText?.(formDataNovo.nameOrNumber);
      const isDefault = formDataNovo.default ? true : false;
      const isOnline = formDataNovo.online ? true : false
      const isV2 = formDataNovo.isV2 ? true : false
      setDefaultN?.(isDefault);
      SetOnlineN?.(isOnline);
      SetV2?.(isV2)
      refresh?.(formDataNovo.nameOrNumber, isDefault, isOnline, isV2,true);
    }}>
      <filter.InputWrapper>
        <label htmlFor="nameOrNumber">
          <Flex align='center' justify='center'>
            <Search />
          </Flex>
        </label>
        <input type="text" placeholder="Digite o nome ou número" id="nameOrNumber" name="nameOrNumber" value={inputValue} defaultValue={text} onChange={(e) => setInputValue(e.target.value)} />
      </filter.InputWrapper>

        <Flex gap="2" justify='between'>
          Ativo
          <Switch size='3' color='gray' id="default" name="default" onClick={()=>{
            setDefaultN?.(!defaultN);
          }} checked={defaultN} />
        </Flex>
        <Flex gap="2" justify='between'>
          Expirado
          <Switch size='3' color='gray' id="online" name="online" onClick={()=>{
            SetOnlineN?.(!onlineN);
          }} checked={onlineN} />
        </Flex>
        <Flex gap="2" justify='between'>
          Reembolso
          <Switch size='3' color='gray' id="isV2" name="isV2" onClick={()=>{
            SetV2?.(!V2);
          }} checked={V2} />
        </Flex>

      <filter.ButtonWrapper>
        <input type="submit" value="Aplicar" />
        <span onClick={() => {
          setInputValue('')
          SetText?.('')
          SetOnlineN?.(false)
          setDefaultN?.(false)
          SetV2?.(false)
          refresh?.('', false, false, false,true);
        }}>Limpar</span>
      </filter.ButtonWrapper>
    </filter.Container>
  )
}