// hooks
import { useEffect, useRef, useState } from 'react'

// styles
import * as s from 'src/components/MainLayout/style'

// components
import { Button } from 'src/components/MainLayout/components/Button'
import { AddUser } from 'src/components/MainLayout/components/Controls/AddContact'
import { ImportContacts } from 'src/components/MainLayout/components/Controls/ImportContacts'
import { Filter } from 'src/components/MainLayout/components/Controls/Filter'
import { Table } from 'src/components/MainLayout/components/Table'
import { FilterCount } from 'src/components/FilterCount'
import { SkeletonTable } from 'src/components/MainLayout/components/Table/Skeleton'
import openSocket from "../../services/socket-io";
// radix
import { Tabs } from '@radix-ui/themes'

// utils
import {
  ExportIcon,
  FilterIcon,
  ImportIcon,
  PlusIcon,
  TrashCanIcon,
  // UserProfilePicture, TODO: only on PRO
  EmptyData,
  UnblockContacts,
} from 'src/utils/icons'

import { DataProps } from './data'
import api from 'src/services/api'
import { useAuth } from 'src/context/Auth/AuthContext'
import { Modal } from 'src/components/MainLayout/components/Table/Modal'
import { toast } from 'react-toastify'
import { Clients } from './clients'
import { FreeTest } from './FreeTest'
import { Suggestion } from './suggestion'
import { Plans } from './plan'
import { Notification } from './Notification'
import { useNavigate, useParams } from 'react-router-dom'

export const Admin = () => {
const params = useParams();
const history = useNavigate()
  const pageReference = useRef<HTMLDivElement>(null);
const savePage = (page:string) =>{
  history("/admin/"+page);
}
  return (
    <s.Container defaultValue={params.page?params.page:'contacts'}>
      <s.Content>
        <h1>Opções</h1>

        <s.TabsList>
          <s.TabsTrigger value="contacts" onClick={()=>savePage("contacts")}>Clientes</s.TabsTrigger>
          <s.TabsTrigger value="FreeTest" onClick={()=>savePage("FreeTest")}>Teste Gratis</s.TabsTrigger>
          <s.TabsTrigger value="Suggestion" onClick={()=>savePage("Suggestion")}>Sugestões</s.TabsTrigger>
          <s.TabsTrigger value="Plans" onClick={()=>savePage("Plans")}>Planos</s.TabsTrigger>
          <s.TabsTrigger value="Notifications" onClick={()=>savePage("Notifications")}>Notificações</s.TabsTrigger>
        </s.TabsList>
      </s.Content>

      <s.Content ref={pageReference} $primary={true}>
        <Tabs.Content value='contacts'>
          <Clients pageReference={pageReference} tab='members' />
        </Tabs.Content>
        <Tabs.Content value='FreeTest'>
          <FreeTest pageReference={pageReference} tab='FreeTest' />
        </Tabs.Content>
        <Tabs.Content value='Suggestion'>
          <Suggestion pageReference={pageReference} tab='Suggestion' />
        </Tabs.Content>
        <Tabs.Content value='Plans'>
          <Plans pageReference={pageReference} tab='Planos' />
        </Tabs.Content>
        <Tabs.Content value='Notifications'>
          <Notification pageReference={pageReference} tab='Notificações' />
        </Tabs.Content>
      </s.Content>
    </s.Container>
  )
}