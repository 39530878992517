import { z } from "zod";

// Schema -> data structure representation
export const EditUserFormSchema = z.object({
  name: z.string().nonempty("Você precisa inserir um nome"),
  password: z.string(),
 // email: z.string().email("Tipo de e-mail inválido"),
  cellphone: z.string().nonempty("O campo de telefone é obrigatório"),
 // timeOut: z.string().nonempty("Você precisa inserir uma data"),
 // plan: z.string().nonempty("Insira o plano"),
});

// type to remove typescript errors by reactHooks
export type EditUserFormData = z.infer<typeof EditUserFormSchema>;
