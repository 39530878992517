// react
import { ReactNode } from "react"

// styled-components
import * as s from './style'

// components
import { Sidebar } from "./components/Sidebar"
import Header from "./components/Header"

export const MainLayout = ({ children,title }: { children: ReactNode,title:string }) => {
  return (
    <s.Container>
      <Sidebar />
      <s.Main>
        <Header path={title} />
        {children}
      </s.Main>
    </s.Container>
  )
}