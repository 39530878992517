// hooks
import React, { useEffect, useState } from 'react'

// react-hook-forms
import { Controller, useForm } from 'react-hook-form'

// zod
import { AddContactFormData, AddContactFormSchema } from 'src/types/add-freetest-form'
import { zodResolver } from '@hookform/resolvers/zod'

// react phone number input
import ReactPhoneInput from "react-phone-number-input"

// rect select
import Select from 'react-select'

// components
import Input from 'src/components/Input'
import { AlertDropdown } from '../../AlertDropdown'

// styles
import * as s from './style'

// utils
import SucessUser from 'src/utils/icons/SucessUser'
import api from 'src/services/api'
import { toast } from 'react-toastify'
import { DataProps } from 'src/pages/Admin/data'
import { Checkbox, Flex, Text } from '@radix-ui/themes'
import { AxiosError } from 'axios'

export const AddUser = ({ onCancel, refresh, editedContact }: { editedContact?: DataProps, onCancel?: () => void, refresh?: (a?: string, b?: boolean, c?: boolean, d?: boolean, e?: boolean) => void }) => {
  const [displayAlert, setDisplayAlert] = useState<boolean>(false)
  const [profile,setProfile] = useState<String>(editedContact?.profile||"user");
  const [plano,setPlano] = useState<number|undefined>(editedContact?.plano||undefined);
  const [ExtraExpire,setExtraExpire] = useState<Date|undefined>(editedContact?.ExtraExpire||undefined);
  const [expire,setExpire] = useState<Date|undefined>(editedContact?.expire||undefined);
  const [hotmartnosend,setHotmartnosend] = useState<boolean>(editedContact?.nohotmart||false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }, // need to show the form validation errors
  } = useForm<AddContactFormData>({
    resolver: zodResolver(AddContactFormSchema)
  })
const [planos,setPlanos] = useState([])

  const onSubmit = async (data: AddContactFormData) => {
   // console.log(data)
    if (!displayAlert) {
      if (editedContact) {
        try {
          await api.put("/updatFreetest/" + editedContact?.id, {
            ...editedContact,...data,profile,plano,ExtraExpire,expire,nohotmart:hotmartnosend
          });
          toast.success("membro atualizado com sucesso");
          refresh?.(undefined, undefined, undefined, undefined, true)
        } catch (err: any) {
          if (err.response.status == 403) {
             if (err.response.data.error == "ERR_NO_PERMISSION") {
              toast.error("Você não tem permissão para isso");
            }
            return;
          }
          toast.error("falha ao se comunicar com servidor")
        }
      }
      else {
        try {
          await api.post("/updatFreetest", {
            ...data,profile,plano,ExtraExpire,expire,nohotmart:hotmartnosend
          });
          toast.success("membro criado com sucesso");
          refresh?.()
        } catch (err: any) {
          if (err.response.status == 403) {
            if (err.response.data.error == "ERR_USER_INVALID") {
              toast.error("falha ao adicionar membro verifique se email está certo");
            } else if (err.response.data.error == "ERR_USER_UNNEW") {
              toast.error("email ja existe...");
            } else if (err.response.data.error == "ERR_NO_PERMISSION") {
              toast.error("Você não tem permissão para isso");
            }
            return;
          }
          toast.error("falha ao se comunicar com servidor")
        }
      }
      return setDisplayAlert(true)
    }

    // close modal
    onCancel?.()
  }

  const options = [{value:"user",label:"Usuario"},{value:"manager",label:"Gerente"},{value:"productor",label:"Produtor"},{value:"admin",label:"Admin"}
  ]

  return (
    <s.Form onSubmit={handleSubmit(onSubmit)}>
      {displayAlert ? (
        <AlertDropdown icon={<SucessUser />} title='Seu membro foi adicionado com sucesso!' />
      ) : (
        <div style={{overflow:"auto",maxHeight:"35em"}}>
          <Input
            type='number2'
            placeholder='Insira o Numero do membro que deseja adicionar'
            register={register}
            errors={errors.number2}
            defaultValue={editedContact?.number}
          >
            Numero
          </Input>
          <Flex direction='column' gap='2'>
            <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>Expira</label>
            <s.DateInput type="date" onChange={(e)=>setExtraExpire(new Date(e.target.value))} defaultValue={editedContact?.AddDate&&new Date(new Date(editedContact.AddDate).getTime()+(86400000*3)).toISOString().substring(0, 10)} />
          </Flex>
          <s.SubmitWrapper style={{marginTop:"2em"}}>
            <s.Reset type="reset" value="Cancelar" onClick={() => { onCancel?.() }} />
            <s.Submit type="submit" value={editedContact ? "Editar" : "Adicionar"} />
          </s.SubmitWrapper>
        </div>
      )}
    </s.Form>
  )
}