// hooks
import React, { useState } from 'react'

// react-hook-forms
import { Controller, useForm } from 'react-hook-form'

// zod
import { AddContactFormData, AddContactFormSchema } from 'src/types/add-member-form'
import { zodResolver } from '@hookform/resolvers/zod'

// react phone number input
import ReactPhoneInput from "react-phone-number-input"

// components
import Input from 'src/components/Input'
import { AlertDropdown } from '../../AlertDropdown'

// styles
import * as s from './style'

// utils
import SucessUser from 'src/utils/icons/SucessUser'
import api from 'src/services/api'
import { toast } from 'react-toastify'
import { DataProps } from 'src/pages/Members/data'
import { Flex } from '@radix-ui/themes'
import { AxiosError } from 'axios'

export const AddMember = ({ onCancel, refresh, editedContact }: { editedContact?: DataProps, onCancel?: () => void, refresh?: (a?:string,b?:boolean,c?:boolean,d?:boolean,e?:boolean) => void }) => {
  const [displayAlert, setDisplayAlert] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }, // need to show the form validation errors
  } = useForm<AddContactFormData>({
    resolver: zodResolver(AddContactFormSchema)
  })

  const onSubmit = async (data: AddContactFormData) => {
    if (!displayAlert) {
      if (editedContact) {
        try {
          await api.put("/member/" + editedContact?.id, {
            email: data.email,
          });
          toast.success("membro atualizado com sucesso");
          refresh?.(undefined, undefined, undefined, undefined,true)
        } catch (err : any) {
          if(err.response.status == 403){
            if(err.response.data.error == "ERR_USER_INVALID"){
              toast.error("falha ao atualizar membro verifique se email está certo");
            }else if(err.response.data.error == "ERR_USER_UNNEW"){
              toast.error("email ja existe...");
            }else if(err.response.data.error == "ERR_NO_PERMISSION"){
              toast.error("Você não tem permissão para isso");
            }
            return;
          }
          toast.error("falha ao se comunicar com servidor")
        }
      }
      else {
        try {
          await api.post("/member", {
            email: data.email,
          });
          toast.success("membro criado com sucesso");
          refresh?.()
        } catch (err:any){
          if(err.response.status == 403){
            if(err.response.data.error == "ERR_USER_INVALID"){
              toast.error("falha ao adicionar membro verifique se email está certo");
            }else if(err.response.data.error == "ERR_USER_UNNEW"){
              toast.error("email ja existe...");
            }else if(err.response.data.error == "ERR_NO_PERMISSION"){
              toast.error("Você não tem permissão para isso");
            }
            return;
          }
          toast.error("falha ao se comunicar com servidor")
        }
      }
      return setDisplayAlert(true)
    }

    // close modal
    onCancel?.()
  }

  return (
    <s.Form onSubmit={handleSubmit(onSubmit)}>
      {displayAlert ? (
        <AlertDropdown icon={<SucessUser />} title='Seu membro foi adicionado com sucesso!' />
      ) : (
        <React.Fragment>
          <Input
            type='email'
            placeholder='Insira o email do membro que deseja adicionar'
            register={register}
            errors={errors.email}
            defaultValue={editedContact?.email}
          >
            Email
          </Input>

          {/*<Flex direction='column' gap='2'>
            <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>dwadwa</label>
            <s.DateInput type="date" />
      </Flex>*/}

          <s.SubmitWrapper>
            <s.Reset type="reset" value="Cancelar" onClick={() => { onCancel?.() }} />
            <s.Submit type="submit" value={editedContact ? "Editar" : "Adicionar"} />
          </s.SubmitWrapper>
        </React.Fragment>
      )}
    </s.Form>
  )
}