import React from "react";

function ExportIconBranco() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.25 9L9 6.75m0 0L6.75 9M9 6.75V15m-3.564 0H5.4c-.84 0-1.26 0-1.581-.164a1.5 1.5 0 01-.656-.655C3 13.861 3 13.44 3 12.6V5.4c0-.84 0-1.26.163-1.58a1.5 1.5 0 01.656-.657C4.139 3 4.559 3 5.398 3h7.205c.838 0 1.257 0 1.578.163.282.144.512.374.656.656.163.321.163.74.163 1.579v7.204c0 .839 0 1.259-.163 1.58a1.5 1.5 0 01-.656.654C13.86 15 13.44 15 12.6 15h-.037"
      ></path>
    </svg>
  );
}

export default ExportIconBranco;