// components
import { Button } from "../Button"
import { localizedStrings } from "../../../../constants/localizedStrings";

// styles
import * as alert from './style'

// types
type TAlertDropdown = {
  icon: any,
  title: string,
  subTitle?: string,
  cancel?: boolean,
  removeButtons?: boolean
}

export const AlertDropdown = ({ icon, title, subTitle, cancel, removeButtons }: TAlertDropdown) => {
  return (
    <alert.Container>
      <span>{icon}</span>
      <div>
        <h2>{title}</h2>
        <h3>{subTitle}</h3>
      </div>
      {!removeButtons && (
        <div>
          <Button label={localizedStrings.concluded} primary={true} dropdownDisabled />
          {cancel && <Button label={localizedStrings.cancel} ghost />}
        </div>
      )}
    </alert.Container>
  )
}