// hooks
import React, { useEffect, useState } from 'react'

// react-hook-forms
import { Controller, useForm } from 'react-hook-form'

// zod
import { AddContactFormData, AddContactFormSchema } from 'src/types/add-user-form'
import { zodResolver } from '@hookform/resolvers/zod'

// react phone number input
import ReactPhoneInput from "react-phone-number-input"

// rect select
import Select from 'react-select'

// components
import Input from 'src/components/Input'
import { AlertDropdown } from '../../AlertDropdown'

// styles
import * as s from './style'

// utils
import SucessUser from 'src/utils/icons/SucessUser'
import api from 'src/services/api'
import { toast } from 'react-toastify'
import { DataProps } from 'src/pages/Admin/data'
import { Checkbox, Flex, Text } from '@radix-ui/themes'
import { AxiosError } from 'axios'

export const AddUser = ({ onCancel, refresh, editedContact }: { editedContact?: DataProps, onCancel?: () => void, refresh?: (a?: string, b?: boolean, c?: boolean, d?: boolean, e?: boolean) => void }) => {
  const [displayAlert, setDisplayAlert] = useState<boolean>(false)
  const [profile,setProfile] = useState<String>(editedContact?.profile||"user");
  const [plano,setPlano] = useState<number|undefined>(editedContact?.plano||undefined);
  const [ExtraExpire,setExtraExpire] = useState<Date|undefined>(editedContact?.ExtraExpire||undefined);
  const [expire,setExpire] = useState<Date|undefined>(editedContact?.expire||undefined);
  const [hotmartnosend,setHotmartnosend] = useState<boolean>(editedContact?.nohotmart||false);
  const [braipnosend,setBraipnosend] = useState<boolean>(editedContact?.nobraip||false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }, // need to show the form validation errors
  } = useForm<AddContactFormData>({
    resolver: zodResolver(AddContactFormSchema)
  })
const [planos,setPlanos] = useState([])
useEffect(()=>{
  (async()=>{
   const {data:plan} = await api.get("/plano");
   setPlanos(plan.map((element:any)=>{
    return{value: element.id, label: element.name}
   }));
  })()
},[])
  const onSubmit = async (data: AddContactFormData) => {
   // console.log(data)
    if (!displayAlert) {
      if (editedContact) {
        try {
          await api.put("/updatEuser/" + editedContact?.id, {
            ...editedContact,...data,profile,plano,ExtraExpire,expire,nohotmart:hotmartnosend,nobraip:braipnosend
          });
          toast.success("membro atualizado com sucesso");
          refresh?.(undefined, undefined, undefined, undefined, true)
        } catch (err: any) {
          if (err.response.status == 403) {
             if (err.response.data.error == "ERR_NO_PERMISSION") {
              toast.error("Você não tem permissão para isso");
            }
            return;
          }
          toast.error("falha ao se comunicar com servidor")
        }
      }
      else {
        try {
          await api.post("/adduser", {
            ...data,profile,plano,ExtraExpire,expire,nohotmart:hotmartnosend
          });
          toast.success("membro criado com sucesso");
          refresh?.()
        } catch (err: any) {
          if (err.response.status == 403) {
            if (err.response.data.error == "ERR_USER_INVALID") {
              toast.error("falha ao adicionar membro verifique se email está certo");
            } else if (err.response.data.error == "ERR_USER_UNNEW") {
              toast.error("email ja existe...");
            } else if (err.response.data.error == "ERR_NO_PERMISSION") {
              toast.error("Você não tem permissão para isso");
            }
            return;
          }
          toast.error("falha ao se comunicar com servidor")
        }
      }
      return setDisplayAlert(true)
    }

    // close modal
    onCancel?.()
  }

  const options = [{value:"user",label:"Usuario"},{value:"manager",label:"Gerente"},{value:"productor",label:"Produtor"},{value:"admin",label:"Admin"}
  ]

  return (
    <s.Form onSubmit={handleSubmit(onSubmit)}>
      {displayAlert ? (
        <AlertDropdown icon={<SucessUser />} title='Seu membro foi adicionado com sucesso!' />
      ) : (
        <div style={{overflow:"auto",maxHeight:"35em"}}>
          <Input
            type='name'
            placeholder='Insira o nome do usuario que deseja adicionar'
            register={register}
            errors={errors.name}
            defaultValue={editedContact?.name}
          >
            Nome
          </Input>
          <Input
            type='email'
            placeholder='Insira o email do membro que deseja adicionar'
            register={register}
            errors={errors.email}
            defaultValue={editedContact?.email}
          >
            Email
          </Input>
          <Input
            type='number2'
            placeholder='Insira o Numero do membro que deseja adicionar'
            register={register}
            errors={errors.number2}
            defaultValue={editedContact?.number}
          >
            Numero
          </Input>
          <Input
            type='password'
            placeholder='Insira a Senha do membro que deseja adicionar'
            register={register}
            autocomplete="one-time-code"
          >
            Senha
          </Input>
          <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>Perfil</label>
          <Select
            options={options.filter(element=>element.value !== "admin")}
            // isMulti
            onChange={(e)=>e&&e?.value&&setProfile(e?.value)}
            defaultValue={options.find(element=>element.value == editedContact?.profile)}
            isSearchable={false}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                '&:hover': { borderColor: '#E5E9EE' },
                borderColor: state.isFocused ? '#B3BFCC' : '#E5E9EE',
                boxShadow: state.isFocused ? '0 0 1px 2px #B3BFCC' : 'none'
              })
            }}
          />
          <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>Plano</label>
          <Select
            options={planos}
            onChange={(e)=>e&&e?.value&&setPlano(e?.value)}
            defaultValue={editedContact?.planolindo&&{id:editedContact?.planolindo.id,label:editedContact?.planolindo.name}}
            // isMulti
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                '&:hover': { borderColor: '#E5E9EE' },
                borderColor: state.isFocused ? '#B3BFCC' : '#E5E9EE',
                boxShadow: state.isFocused ? '0 0 1px 2px #B3BFCC' : 'none'
              })
            }}
          />


          <Flex direction='column' gap='2'>
            <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>Plano Expira</label>
            <s.DateInput type="date" onChange={(e)=>setExpire(new Date(e.target.value))} defaultValue={editedContact?.expire&&new Date(editedContact?.expire).toISOString().substring(0, 10)} />
          </Flex>
          {editedContact?.extras && <div onClick={async()=>{

const tempoZuado = +(await window.prompt("escreva quantos meses") || "") || 1;
const numeros = +(await window.prompt("escreva quantos numeros vão ser adicionados") || "") || 1;
const tempo = 2592000000*tempoZuado;

const NumeroAdicionais = numeros;

const jaadquiridos =Number(editedContact?.extras);

const tempoJaAdquirido = new Date(editedContact?.ExtraExpire).getTime();

const totalDeNumeros = NumeroAdicionais+jaadquiridos;

const tempoImplementado =NumeroAdicionais*tempo;

const tempoReduzido = tempoImplementado/totalDeNumeros

const result = new Date(Number ( (tempoReduzido+tempoJaAdquirido) . toFixed(0)));
alert("coloque a data para dia "+ result.toLocaleDateString());
}}>calcular data extra</div>}
          <Input
            type='extra'
            placeholder='Insira o Numero do membro que deseja adicionar'
            register={register}
            errors={errors.extra}
            defaultValue={editedContact?.extras?String(editedContact?.extras):String(0)}
          >
            Quantidade de números extras
          </Input>
          <Flex direction='column' gap='2'>
            <label htmlFor="" style={{ fontSize: 14, color: '#738699', fontWeight: 500 }}>Extra Expira</label>
            <s.DateInput type="date" onChange={(e)=>setExtraExpire(new Date(e.target.value))} defaultValue={editedContact?.ExtraExpire&&new Date(editedContact?.ExtraExpire).toISOString().substring(0, 10)} />
          </Flex>
          {editedContact&&<Text as="label" size="2">
  <Flex gap="2">
    <Checkbox onCheckedChange={(e:any)=>setHotmartnosend(e)} defaultChecked={editedContact?.nohotmart?true:false} />
    Remover sicronização com hotmart
  </Flex>
  <Flex gap="2">
    <Checkbox onCheckedChange={(e:any)=>setBraipnosend(e)} defaultChecked={editedContact?.nobraip?true:false} />
    Remover sicronização com Braip
  </Flex>
          </Text>}
          <Input
            type='HotMartName'
            placeholder='Insira o Nome do membro que deseja adicionar'
            register={register}
            defaultValue={editedContact?.HotMartName||""}
          >
            Nome da hotmart
          </Input>
          <s.SubmitWrapper style={{marginTop:"2em"}}>
            <s.Reset type="reset" value="Cancelar" onClick={() => { onCancel?.() }} />
            <s.Submit type="submit" value={editedContact ? "Editar" : "Adicionar"} />
          </s.SubmitWrapper>
        </div>
      )}
    </s.Form>
  )
}