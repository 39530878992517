import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: inline-block;

  display: flex;
`;

export const Nav = styled.nav`
  display: flex;
  gap: 1rem;
  cursor: pointer;
`;

export const Img = styled.img`
  cursor: pointer;
`;

export const Triangle = styled.div`
  position: absolute;

  width: max-content;
  border-bottom: 1.5rem solid ${({ theme }) => theme.colors.neutral0};
  border-left: 0.875rem solid transparent;
  border-right: 0.875rem solid transparent;

  right: 1.25rem;
  top: -1.5rem;
`;

export const Content = styled.div`
  position: absolute;
  right: 0rem;
  top: 3.5rem;

  padding: 1.125rem;

  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.colors.neutral1100};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  min-height: 5rem;
  width: 15rem;
  z-index: 1;

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    list-style: none;

    li {
      padding: 0.5rem;
      border-radius: 5px;
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors.neutral900};
      }
    }
  }
`;

export const Fade = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;

  display: block;
  border-radius: 0 0 0.625rem 0.625rem;

  width: 100%;
  height: 4rem;

  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0),
    rgba(255, 255, 255, 0.9) 90% 100%
  );
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  h1,
  h2 {
    text-align: center;
  }

  h1 {
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral100};
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral500};
  }

  button {
    width: 300px;
    height: 42px;
    border: 0;
    border-radius: 50px;
    padding: 0 24px;
    background-color: ${({ theme }) => theme.colors.neutral100};
    color: ${({ theme }) => theme.colors.neutral1100};
    cursor: pointer;
  }

  span {
    color: ${({ theme }) => theme.colors.neutral500};
    font-size: 14px;
    cursor: pointer;
  }
`;
