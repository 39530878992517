// hooks
import React, { ReactNode, useEffect, useState } from 'react'

// react-hook-forms
import { useForm } from 'react-hook-form'

// zod
import { EditUserFormData, EditUserFormSchema } from 'src/types/edit-user-form'
import { zodResolver } from '@hookform/resolvers/zod'

// mask
import { normalizePhoneNumber } from "src/utils/input-mask"

// styles
import * as s from './style'
import * as form from 'src/components/MainLayout/components/Controls/AddContact/style'

// components
import { Modal } from 'src/components/MainLayout/components/Table/Modal'
import Input from 'src/components/Input'
import { Alert } from 'src/utils/icons'
import { useAuth } from 'src/context/Auth/AuthContext'
import { toast } from 'react-toastify'
import api from 'src/services/api'

type DropdownProps = {
  image: any,
  children: ReactNode,
}

export default function Dropdown(
  { image, children }: DropdownProps
) {
  const { handleLogout } = useAuth()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [editProfile, setEditProfile] = useState<boolean>(false)
  const [logOut, setLogOut] = useState<boolean>(false)
  const { user, SetUser } = useAuth()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }, // need to show the form validation errors
  } = useForm<EditUserFormData>({
    resolver: zodResolver(EditUserFormSchema)
  })

  // cellphone number mask
  const phoneValue = watch('cellphone')
  useEffect(() => {
    //console.log(phoneValue)
    // setValue('cellphone', normalizePhoneNumber(phoneValue))
  }, [phoneValue, setValue])

  const onSubmit = async (data: EditUserFormData) => {
    try {
      if (!user) return;
      await api.put(`/users/${user.id}`, {
        ...user, ...{
          ...data, name: data.name,
          number: String(data?.cellphone).replace(/\D/g, "")
        }
      });
      SetUser((newdata: any) => {
        return {
          ...newdata, ...{
            name: data.name,
            number: String(data?.cellphone).replace(/\D/g, "")
          }
        }
      });
      toast.success("Perfil editado com sucesso");
      setValue("password", "");
      setEditProfile(false);
    } catch {
      toast.error("falha ao editar perfil")
    }
  }

  // display dropdown
  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <s.Container>
      <s.Nav onClick={handleClick}>
        {children}
        <s.Img
          src={image}
          alt=''
        />
      </s.Nav>

      {/* TODO: only on pro */}
      {isOpen && (
        <s.Content>
          <ul>
            <li onClick={() => setEditProfile(true)}>Editar perfil</li>
            <li onClick={() => setLogOut(true)}>Sair</li>
          </ul>
        </s.Content>
      )}

      {editProfile && (
        <Modal state={setEditProfile}>
          <form.Form onSubmit={handleSubmit(onSubmit)}>
            <React.Fragment>
              <Input
                type='name'
                placeholder='Insira seu nome'
                register={register}
                errors={errors.name}
                defaultValue={user?.name}
              >
                Nome
              </Input>
              <Input
                type='cellphone'
                placeholder='Insira seu número'
                register={register}
                errors={errors.cellphone}
                defaultValue={String(user?.number)}
              >
                Número
              </Input>
              {/*<Input
                type='email'
                placeholder='Insira seu e-mail'
                register={register}
                errors={errors.email}
              >
                E-mail
      </Input>*/}
              {/*<Input
                type='plan'
                placeholder='Defina um plano'
                register={register}
                errors={errors.plan}
              >
                Plano
      </Input>*/}
              {/*<Input
                type='timeOut'
                placeholder='Insira uma data de término'
                register={register}
                errors={errors.timeOut}
              >
                Tempo que termina
      </Input>*/}
              <Input
                type='password'
                placeholder='Insira uma senha'
                register={register}
                errors={errors.password}
              >
                Senha
              </Input>

              <form.SubmitWrapper>
                <form.Reset type="reset" value="Cancelar" onClick={() => setEditProfile(false)} />
                <form.Submit type="submit" value="Salvar" />
              </form.SubmitWrapper>
            </React.Fragment>
          </form.Form>
        </Modal>
      )}

      {logOut && (
        <Modal state={setLogOut}>
          <s.ContainerFlex>
            <Alert />
            <div>
              <h1>Deseja sair do painel do cliente?</h1>
              <h2>É importante que o seu número de WhatsApp esteja cadastrado corretamente para que sua MyLeads funcione corretamente.</h2>
            </div>
            <button onClick={() => { setLogOut(false); handleLogout() }}>Sair</button>
            <span onClick={() => setLogOut(false)}>Cancelar</span>
          </s.ContainerFlex>
        </Modal>
      )}
    </s.Container>
  )
}