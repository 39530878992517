// hooks
import React, { useEffect, useRef, useState } from 'react'
import { useSortableData } from 'src/hooks/useSortableData'

// radix
import { Checkbox, Flex } from '@radix-ui/themes'

// styles
import * as s from './style'
import { Alert, HorizontalControl } from 'src/utils/icons'

// components
import { Modal } from './Modal'
import { AddUser } from '../Controls/AddUser'
import { Button } from '../Button'
import api from 'src/services/api'
import { toast } from 'react-toastify'
import { DataProps } from 'src/pages/Admin/data'
import { useAuth } from 'src/context/Auth/AuthContext'
import { copy } from 'src/utils/copy'


// types
type TableProps = {
  data: DataProps[],
  blockedContactsTab?: boolean,
  setData?: React.Dispatch<React.SetStateAction<DataProps[]>>;
  refresh?: () => void;
  setSelectedContacts?: (data: DataProps[]) => void;
  showDeleteModal?: boolean;
  setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
  bulkDelete?:()=>void
}

export const Table = ({ data, blockedContactsTab, setShowDeleteModal,bulkDelete, showDeleteModal, setData, refresh, setSelectedContacts }: TableProps) => {
  const [selectAll, setSelectAll] = useState(false)
  const [checkedState, setCheckedState] = useState<boolean[]>(data.map(() => false));
  const [selectDeleteContact, setSelectDeleteContact] = useState<undefined | number>(undefined)
  const [verticalIconDropdown, setVerticalIconDropdown] = useState<number | null>(null)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [editedContact, SetEditedContact] = useState<DataProps | undefined>(undefined);
  const { items, requestSort, sortConfig } = useSortableData(data) // sort table
  const { user } = useAuth();
  // add class when sort table
  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // checkbox check all
  const handleCheckAll = () => {
    setSelectAll(!selectAll)

    setCheckedState(checkedState.map(item => !selectAll));

    const updatedData = data.map((idx => { return { ...idx, checked: !selectAll } }));

    setData?.(updatedData);

    const everyIsFalse = updatedData.some(idx => idx.checked === false);

    setSelectedContacts?.(everyIsFalse ? [] : updatedData);
  }

  const quebrarTexto = (texto: string) => {
    const caracteresPorLinha = 10;
    let textoQuebrado = [];
    for (let i = 0; i < texto.length; i += caracteresPorLinha) {
      textoQuebrado[textoQuebrado.length] = texto.substring(i, i + caracteresPorLinha);
      textoQuebrado[textoQuebrado.length] = <br />
    }
    return textoQuebrado;
  }

  // checkbox check
  const handleCheckbox = (position: number) => {
    setCheckedState(prevState =>
      prevState.map((item, idx) =>
        idx === position ? !item : item
      )
    );

    data[position].checked = !data[position].checked
    setSelectedContacts?.(data.filter(idx => idx.checked === true))
  };

  // handle vertical icon dropdown
  const handleVerticalIconDropdown = (index: any) => {
    setVerticalIconDropdown(dropIndex => {
      return dropIndex === index ? null : index
    });
  }

  // handle vertical icon dropdown close when click out
  const buttonRef = useRef<HTMLTableSectionElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setVerticalIconDropdown(null)
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [buttonRef]);

  return (
    <s.TableContainer>
      <s.TableHead>
        <tr>
          {/*<td
            onClick={() => requestSort('name')}
            className={getClassNamesFor('name')}
            style={{ cursor: 'pointer' }}
          >
            Nome
          </td>
          <td
            onClick={() => requestSort('email')}
            className={getClassNamesFor('email')}
            style={{ cursor: 'pointer' }}
          >
            E-mail
  </td>*/}
          <td className={getClassNamesFor('name')} onClick={() => requestSort('name')} style={{ cursor: 'pointer' }}>Nome</td>
          <td
            className={getClassNamesFor('email')} onClick={() => requestSort('email')} style={{ cursor: 'pointer' }}
          >Email</td>
          {/* <td>Etiquetas</td> TODO: Only on pro */}
          <td className={getClassNamesFor('number')}>telefone contato</td>
          <td className={getClassNamesFor('profile')}>Perfil</td>
          <td className={getClassNamesFor('plan')}>Plano</td>
          <td className={getClassNamesFor('expire')}>Expira</td>
          <td className={getClassNamesFor('extras')}>Extra</td>
          <td className={getClassNamesFor('ExtraExpire')}>Expira(e)</td>
          <td className={getClassNamesFor('affiliated')}>Afiliado</td>
          <td className={getClassNamesFor('idt')}>ID de transação</td>
          <td style={{ width: 25 }}></td>
        </tr>
      </s.TableHead>
      <s.TableBody ref={buttonRef}>
        {items.map((contact, index) => (
          <tr key={index}>
            {/* <td data-label="Nome">
              <div>
        */ }

            {/* TODO: picture only on PRO */}
            {/* {contact.profilePicture ? (
                  <img src={contact.profilePicture} alt={`${contact.name} profile`} width={32} />
                ) : (
                  <UserProfilePicture />
                )} */}
            {/*   {contact.name}
              </div>
            </td>
          <td data-label="E-mail">
              {contact.email}
              <div></div>
            </td>
            */}

            <td data-label="name" onClick={()=>copy(contact.name)}>{quebrarTexto(contact.name)}</td>
            <td data-label="email" onClick={()=>copy(contact.email)}>
                {quebrarTexto(contact.email)}
            </td>

            {/* TODO: td das tags na PRO */}
            {/* <td data-label="Etiquetas">
              <div>
                {contact.tags?.map((tag: string) => (<Tag key={tag}>{tag}</Tag>))}
              </div>
            </td> */}
            <td data-label="number" onClick={()=>copy(contact.number)}>{contact.number}</td>
            <td data-label="profile">{contact.profile}</td>
            <td data-label="plan">{contact.plano?contact.planolindo.name:"Sem Plano"}</td>
            <td data-label="expire">{contact.expire?new Date(contact.expire).toLocaleDateString():contact.status == "Chargeback"?"Chargeback":contact.status == "RR"?"REEMBOLSO":"Expirado"}</td>
            <td data-label="plan">{contact.extras||"Sem extra"}</td>
            <td data-label="expire">{contact.ExtraExpire?new Date(contact.ExtraExpire).toLocaleDateString():contact.status == "Chargeback"?"Chargeback": contact.status == "RR"?"REEMBOLSO":"Expirado"}</td>
            <td data-label="expire" onClick={()=>copy(contact.affiliated)}>{quebrarTexto(contact.affiliated||"")}</td>
            <td data-label="idt"onClick={()=>copy(contact.idt)} >{quebrarTexto((contact.idt||"")+(contact.nohotmart&&"(ignorar Hotmart)"||""))}</td>
            <td
              data-label=""
              style={{ cursor: 'pointer' }}
              onClick={() => handleVerticalIconDropdown(index)}
            >
              <HorizontalControl />

              {verticalIconDropdown === index && (
                <s.Dropdown className='dropdown_options'>
                  <ul>
                    {blockedContactsTab ? (
                      <>
                        <li>Desbloquear contato</li>
                        <li>Excluir contato</li>
                      </>
                    ) : (
                      <>
                        <li onClick={() => { SetEditedContact(contact); setEditModal(true); }}>Editar Usuario</li>
                        {/*<li onClick={() => { SetEditedContact(contact); setEditModal(true); }}>Enviar recuperação de senha</li>
                        <li onClick={() => { SetEditedContact(contact); setEditModal(true); }}>Enviar criação de senha</li>*/}
                      </>
                    )}

                  </ul>
                </s.Dropdown>
              )}
            </td>
          </tr>
        ))}
      </s.TableBody>

      {/* vertical dropdown modals */}
      {editModal && (
        <Modal state={setEditModal}>
          <AddUser refresh={refresh} onCancel={() => { setEditModal(false) }} editedContact={editedContact} />
        </Modal>
      )}

      {showDeleteModal && (
        <Modal state={setShowDeleteModal}>
          <s.ContainerFlex>
            <Alert />
            <div>
              <h1>Deseja realmente excluir {selectDeleteContact?"esse membro":"esses membros"}?</h1>
              <h2>O{selectDeleteContact?"":"s"} {selectDeleteContact?"membro":"membros"} não poderá ser utilizado.</h2>
            </div>
            <button onClick={async () => {

              if(selectDeleteContact){try {
                selectDeleteContact && await api.delete("/member/" + selectDeleteContact);
                setData?.(data.filter(elmenet => elmenet.id !== selectDeleteContact))
                setCheckedState(data.filter(elmenet => elmenet.id !== selectDeleteContact).map(a=>false))
                toast.success("sucesso ao deletar")
              } catch {
                toast.error("falha ao deletar")
              }}else{
                try{
                 await bulkDelete?.();
                  setSelectAll(false)
                  setCheckedState(data.map(()=>false))
                }catch{}
              }
              setSelectDeleteContact(undefined);
              setShowDeleteModal?.(false)

            }}>Excluir</button>
            <span onClick={() =>
              setShowDeleteModal?.(false)
            }>Cancelar</span>
          </s.ContainerFlex>
        </Modal>
      )}
    </s.TableContainer>
  )
}