import React from "react";

function SendEmail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <g clipPath="url(#clip0_254_2194)" filter="url(#filter0_d_254_2194)">
        <rect
          width="76"
          height="76"
          x="14"
          y="14"
          fill="#fff"
          rx="38"
          shapeRendering="crispEdges"
        ></rect>
        <rect
          width="75"
          height="75"
          x="14.5"
          y="14.5"
          stroke="#E5E9EE"
          rx="37.5"
          shapeRendering="crispEdges"
        ></rect>
        <path
          fill="#445568"
          d="M37.75 69.5c-.48 0-.958-.192-1.342-.575l-3.833-3.833c-.767-.767-.767-1.917 0-2.684.767-.766 2.012-.766 2.683 0L37.75 64.9l6.325-6.325c.767-.767 1.917-.767 2.683 0 .767.767.767 1.917 0 2.683l-7.666 7.667a1.892 1.892 0 01-1.342.575z"
          opacity="0.3"
        ></path>
        <path
          fill="#445568"
          fillRule="evenodd"
          d="M36 40a2 2 0 012-2h32a2 2 0 012 2v22a2 2 0 01-2 2H53.967C53.453 56.182 46.948 50 39 50c-1.027 0-2.03.103-3 .3V40zm30.289 1.68a1.5 1.5 0 111.422 2.64l-13 7a1.5 1.5 0 01-1.422 0l-13-7a1.5 1.5 0 011.422-2.64L54 48.295l12.289-6.617z"
          clipRule="evenodd"
        ></path>
      </g>
      <rect
        width="103"
        height="103"
        x="0.5"
        y="0.5"
        stroke="#F7F8FA"
        rx="51.5"
      ></rect>
      <defs>
        <filter
          id="filter0_d_254_2194"
          width="106"
          height="106"
          x="-1"
          y="4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="5"
            result="effect1_dropShadow_254_2194"
          ></feMorphology>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.101961 0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0.05 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_254_2194"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_254_2194"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_254_2194">
          <rect width="104" height="104" fill="#fff" rx="52"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SendEmail;