import { z } from "zod";

// Schema -> data structure representation
export const LoginFormSchema = z.object({
  email: z
    .string()
    .nonempty("Você precisa digitar seu e-mail")
    .email("E-mail inválido!"),
  password: z.string().nonempty("Você precisa digitar sua senha"),
});

// type to remove typescript errors by reactHooks
export type LoginFormData = z.infer<typeof LoginFormSchema>;
