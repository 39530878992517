// radix
import { Flex, Switch, Text } from "@radix-ui/themes"

// styles
import * as filter from './style'

// utils
import { Search } from "src/utils/icons"
import { useAuth } from "src/context/Auth/AuthContext"

// router dom
import { useLocation } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const Filter = ({isDefault,isOnline,text, SetText, refresh, setDefaultN, SetOnlineN, SetV2,IsV2,setV1,V1 }: {setV1: Dispatch<SetStateAction<boolean>>,V1:boolean,IsV2?:boolean,isDefault?:boolean,isOnline?:boolean, text?:string,SetV2?: (bool: boolean) => void, SetOnlineN?: (bool: boolean) => void, setDefaultN?: (bool: boolean) => void, SetText?: (text: string) => void, refresh?: (string: string, defaultN: boolean, OnlineN: boolean, V2n: boolean,pageP:boolean,showAll2?: boolean,V1n?:boolean) => void }) => {


  const {user} = useAuth()
  const [isActived, setIsActived] = useState<boolean>(
    () => {
      const storedValue = localStorage.getItem("isActived");
      return storedValue !== null ? JSON.parse(storedValue) : false;
    }
  );
  const [isExpired, setIsExpired] = useState<boolean>(
    () => {
      const storedValue = localStorage.getItem("isExpired");
      return storedValue !== null ? JSON.parse(storedValue) : false;
    }
  );

  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("isActived", JSON.stringify(isActived));
    localStorage.setItem("isExpired", JSON.stringify(isExpired));
  }, [isDefault, isOnline, isActived, isExpired]);



  const toggleIsActived = () => setIsActived((prev) => !prev);
  const toggleIsExpired = () => setIsExpired((prev) => !prev);

  return (
    <filter.Container onSubmit={(event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);

      const formDataNovo = Object.fromEntries(formData.entries()) as unknown as {isV1:boolean, nameOrNumber: string, isV2: boolean, online: boolean, default: boolean }
      SetText?.(formDataNovo.nameOrNumber);
      const isDefault = formDataNovo.default ? true : false;
      const isOnline = formDataNovo.online ? true : false
      const isV2 = formDataNovo.isV2 ? true : false
      const isV1 = formDataNovo.isV1 ? true : false
      setDefaultN?.(isDefault);
      SetOnlineN?.(isOnline);
      SetV2?.(isV2)
      setV1?.(isV1)
      refresh?.(formDataNovo.nameOrNumber, isDefault, isOnline, isV2,true,undefined,isV1);
    }}>
      <filter.InputWrapper>
        <label htmlFor="nameOrNumber">
          <Flex align='center' justify='center'>
            <Search />
          </Flex>
        </label>
        <input defaultValue={text} type="text" placeholder="Digite o nome ou número" id="nameOrNumber" name="nameOrNumber"  />
      </filter.InputWrapper>

      {(location.pathname === "/clients" || location.pathname === "/admin") &&user?.profile=="admin"&& (
        <>
          <Text as="label" size="2">
            <Flex gap="2" justify='between'>
              Online
              <Switch size='3' color='gray' id="online" name="online" defaultChecked={isOnline?true:false} />
            </Flex>
          </Text>
          <Text as="label" size="2">
            <Flex gap="2" justify='between'>
              Beta
              <Switch size='3' color='gray' id="default" name="default" defaultChecked={isDefault?true:false} />
            </Flex>
          </Text>
          <Text as="label" size="2">
            <Flex gap="2" justify='between'>
              V2
              <Switch size='3' color='gray' id="isV2" name="isV2"  defaultChecked={IsV2?true:false} />
            </Flex>
          </Text>
          <Text as="label" size="2">
            <Flex gap="2" justify='between'>
              V1
              <Switch size='3' color='gray' id="isV1" name="isV1"  defaultChecked={V1?true:false} />
            </Flex>
          </Text>
        </>
      )}

      {(location.pathname === "/admin" || location.pathname === "/manager") && (
        <>
          <Text as="label" size="2">
            <Flex gap="2" justify='between'>
              Ativado
              <Switch size='3' color='gray' id="isV2" name="isV2" onClick={toggleIsActived} checked={isActived} />
            </Flex>
          </Text>
          <Text as="label" size="2">
            <Flex gap="2" justify='between'>
              Expirado
              <Switch size='3' color='gray' id="isV2" name="isV2" onClick={toggleIsExpired} checked={isExpired} />
            </Flex>
          </Text>
        </>
      )}

      <filter.ButtonWrapper>
        <input type="submit" value="Aplicar" />
        <span onClick={() => {SetText?.('')
        setDefaultN?.(false);
        SetOnlineN?.(false);
        SetV2?.(false)
        setV1?.(false)
      refresh?.("", false, false, false,true,undefined,false);
      }}>Limpar</span>
      </filter.ButtonWrapper>
    </filter.Container>
  )
}