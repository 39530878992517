export const es = {
  add: "Agregar",
  no: "No",
  yes: "Sí",
  exit: "Salir",
  admin: "Admin",
  name: "Nombre",
  online: "En línea",
  access: "Acceso",
  offline: "Fuera de línea",
  total: "Total",
  save: "Guardar",
  email: "Correo electrónico",
  clean: "Limpiar",
  filter: "Filtrar",
  cancel: "Cancelar",
  contacts: "Contactos",
  number: "Número",
  status: "Estado",
  members: "Miembros",
  backups: "Copias de seguridad",
  update: "Editar",
  select: "Seleccionar",
  options: "Opciones",
  exclude: "Excluir",
  default: "Por defecto",
  producer: "Productor",
  concluded: "Concluido",
  description: "Descripción",
  observation: "Observación",
  mainLayout: {
    components: {
      controls: {
        addContact: {
          numberUpdatedSuccessfully: "Número actualizado correctamente",
          failToCreateNumber: "Error al crear número",
          yourNumberWasAddedSuccessfully: "Tu número se agregó correctamente",
          insertContactNumber: "Insertar número de contacto",
          insertDescriptionToYourNumber: "Insertar descripción para tu número",
          yourContactWasAddedSuccessfully: "Tu contacto se agregó correctamente",
        },
        addMember: {
          memberUpdatedSuccessfully: "Miembro actualizado correctamente",
          failToUpdateMemberCheckYourEmail: "Error al actualizar miembro, revisa tu correo electrónico",
          emailAlreadyExists: "El correo electrónico ya existe...",
          youCantDoIt: "No puedes hacerlo",
          failToCommunicateServer: "Error al comunicarse con el servidor",
          memberCreatedSuccessfully: "Miembro creado correctamente",
          insertMemberEmail: "Inserta el correo electrónico del miembro que deseas agregar",
        },
        filter: {
          online: "En línea",
          beta: "Beta",
          V2: "V2",
          active: "Activo",
          expired: "Caducado",
          apply: "Aplicar",
          typeNameOrNumber: "Escribe el nombre o número",
        },
        importContacts: {
          anyImportWasFound: "No se encontró ninguna importación",
          wasNoFoundImportCreateOne: "No encontramos ninguna importación realizada. ¡Crea tu primera importación!",
          position: "Posición",
          date: "Fecha",
          import: "Importar",
          newImport: "Nueva importación",
        }
      },
      table: {
        unlockContact: "Desbloquear contacto",
        deleteContact: "Eliminar contacto",
        editContact: "Editar contacto",
        reallyWishToDeleteThisNumber: "¿Realmente deseas eliminar este número?",
        numberCannotUse: "El número no puede usarse.",
        successToDelete: "Eliminación exitosa",
        failToDelete: "Error al eliminar",
      },
      tableMember: {
        contactNumber: "Número de contacto",
        whatsapp: "Whatsapp",
        includeDate: "Fecha de inclusión",
        notRegistered: "No registrado",
        editMember: "Editar miembro",
        excludeMember: "Excluir miembro",
        wishReallyToDelete: "¿Realmente deseas eliminar ",
        thisMember: "Este miembro",
        thoseMember: "Estos miembros",
        the: "El",
        thePlural: "Los",
        member: "Miembro",
        members: "Miembros",
        cantBeUse: "No se puede usar.",
      },
    },
  },
  layout: {
    main: {
      components: {
        header: {
          dropdown: {
            profileUpdatedSuccessfully: "Perfil actualizado correctamente",
            failToEditProfile: "Error al editar perfil",
            editProfile: "Editar perfil",
            insertYourName: "Inserta tu nombre",
            insertYourPhone: "Inserta tu teléfono",
            wishToExitPanel: "¿Deseas salir del panel de cliente?",
            whatsappAlert: "Es importante que tu número de WhatsApp esté registrado correctamente para que tu MyLeads funcione correctamente.",
          },
          plan: "Plan: ",
          valideAt: "Válido hasta: ",
          expiredLicenseRenew: "LICENCIA CADUCADA, RENUEVA TU PLAN",
          hello: "Hola",
          additionalLicense: "Licencia adicional: ",
        },
        sidebar: {
          registeredNumbers: "Números registrados",
          myNumbers: "Mis números",
          tolltipAdminPage: "Página de administrador",
          tolltipProducerPage: "Página de productor",
          install: "Instalar",
          installTolltip: "Instalar extensión Myleads",
          tutorial: "Tutorial",
          watchTutorials: "Ver tutoriales",
          speakSupport: "Hablar con soporte"
        }
      }
    }
  },
  pages: {
    admin: {
      clients: {
        membersArea: "Área de miembros",
        defineMembers: "Puedes definir qué miembros estarán autorizados para usar tu copia de seguridad en MyLeads Members.",
        youHaveNoRegisteredMember: "Todavía no has registrado ningún miembro",
        yourMembersOnlyShowWhenWasAdd: "Tus Miembros solo se pueden ver después de ser agregados.",
      }
    },
    clients: {
      howToAddNumberOnPanel: "Cómo agregar número en el panel",
      learnHowToAddNumbersOnPanel: "Aprende cómo agregar número en tu panel",
      myLeadsLite: "MyBots (extensión)",
      whatsappNumbers: "Números de Whatsapp",
      allowNumbersToUse: "Puedes definir qué números estarán autorizados para usar tu licencia en MyBots (extensión).",
      howAddNumber: "Cómo agregar número",
      youHaveNotRegisteredNumber: "Todavía no has registrado ningún número",
      yourNumbersOnlySeeAfterAdd: "Tus Números solo se pueden ver después de ser agregados.",
      allContacts: "Todos los Contactos",
      hasAnyContact: "No encontramos ningún contacto",
    },
    createPassword: {
      differentPasswords: "Contraseñas diferentes",
      passwordCreatedSuccessfully: "Contraseña creada correctamente",
      failToCreatePassword: "Error al crear contraseña",
      createYourPassword: "Crea tu contraseña",
      nowCreateNewPassword: "Ahora solo crea tu contraseña para una nueva. ¡Tu contraseña necesita ser segura!",
      yourPasswordWasChangedSuccessfully: "Tu contraseña se cambió",
      nowYouCanAccessYourAccount: "Ahora puedes acceder a tu cuenta.",
      insertYourPassword: "Inserta tu contraseña",
      confYourPassword: "Confirma tu contraseña",
      confPassword: "Confirmar contraseña",
      conclued: "Concluir",
      changePassword: "Cambiar contraseña",
    },
    forgotPassword: {
      sentSuccessfully: "Enviado correctamente",
      failToSentRecovery: "Error al enviar el correo de recuperación, inténtalo de nuevo en 5 minutos",
      forgotPassword: "¿Olvidaste tu contraseña?",
      insertYourEmailUnderRecovery: "Inserta tu correo electrónico a continuación y te enviaremos un correo electrónico para recuperar tu contraseña.",
      checkYourBoxEmail: "Revisa tu bandeja de entrada de correo electrónico",
      sentEmailAFewMinutes: "Enviaremos un correo electrónico en unos momentos.",
      insertYourEmail: "Inserta tu correo electrónico",
      resentEmail: "Reenviar correo electrónico",
      backAndDoLogin: "Volver y hacer login",
    },
    login: {
      accessYourAccount: "Accede a tu cuenta",
      insertYourEmailAndPassword: "Inserta tu correo electrónico y contraseña para iniciar sesión.",
      insertEmail: "Inserta tu correo electrónico",
      insertPassword: "Inserta tu contraseña",
      forgetPassword: "¿Olvidaste tu contraseña?",
      haveNoAccount: "¿No tienes una cuenta?",
      register: "Registrarse"
    },
    manager: {
      myClients: "Mis clientes",
      whatsappNumbers: "Números de Whatsapp",
      totalActive: "Total activo: ",
      totalCanceled: "Total cancelado: ",
    },
    register: {
      successToCreate: "Éxito al crear",
      failToRegister: "Error al registrar",
      createYourAcccount: "Crea tu cuenta",
      insertYourCorrectData: "Inserta los datos correctos para registrarte.",
      insertYourNumberAndLastNumber: "Inserta tu nombre y apellido",
      insertYourContactPhone: "Inserta tu teléfono de contacto",
      firstNameAndLastName: "Nombre y apellido",
      insertYourPassword: "Inserta tu contraseña",
      confirYourPassword: "Confirma tu contraseña",
      createAccount: "Crear cuenta",
      wasAccount: "¿Ya tienes una cuenta?",
      doLogin: "Inicia sesión",
    }
  },
  types: {
    loginForm: {
      youNeedTypeYourEmail: "Necesitas escribir tu correo electrónico",
      emailIsInvalid: "¡Correo electrónico no válido!",
      youNeedTypeYourPassword: "Necesitas escribir tu contraseña",
    },
    recoveryPassword: {
      typeNewPassword: "Necesitas escribir tu nueva contraseña",
      youNeedToRepeatYourNewPassword: "Necesitas repetir tu nueva contraseña",
      samePasswords: "Las contraseñas deben ser iguales",
    },
    registerForm: {
      youNeedTypeYourEmail: "Necesitas escribir tu nombre",
      typeYourPhone: "Necesitas escribir tu teléfono",
      invalidEmail: "¡Correo electrónico no válido!",
    }
  },
}
