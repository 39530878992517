// hooks
import { useState } from 'react';

// react-hook-forms
import { useForm } from 'react-hook-form'

// zod
import { RecoveryPasswordFormData, RecoveryPasswordFormSchema } from 'src/types/recovery-password-form'
import { zodResolver } from '@hookform/resolvers/zod'

// layout
import FormLayout from 'src/layout/Form';

// styles
import * as s from 'src/pages/Login/style'

// components
import { Header } from 'src/components/Header';
import Input from 'src/components/Input';
import { Submit } from 'src/components/Submit';

// utils
import { RecoveryPassswordIcon } from 'src/utils/icons';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'src/services/api';
import { toast } from 'react-toastify';

export const CreatePassword = () => {
  const [submit, setSubmit] = useState<Boolean>(false)
  const {
    register,
    handleSubmit,
    formState: { errors }, // need to show the form validation errors
  } = useForm<RecoveryPasswordFormData>({
    resolver: zodResolver(RecoveryPasswordFormSchema)
  })
  const history = useNavigate()
 const params =  useParams()
  const onSubmit = (data: RecoveryPasswordFormData) => {
    if(submit){
     return history("/")
    }
    (async()=>{try {
      if(data.password !== data.confirmPassword) {
         return toast.error("senhas diferentes");  
      }
await api.post("/passwordreset",{
          recoveryCode:params.id,password:data.password
      })
      toast.success("Senha criada com sucesso")
      setSubmit(true)
} catch (err) {
toast.error("falha ao criar senha");
}})()
  }

  return (
    <FormLayout>
      <s.Wrapper>
        {!submit ? (
          <Header
            title='Crie sua senha'
            subTitle='Agora é só criar sua senha para uma nova. Sua senha precisa ser segura!'
          />
        ) : (
          <Header
            image={<RecoveryPassswordIcon />}
            title='Sua senha foi alterada
            com sucesso!'
            subTitle='Agora você já pode acessar sua conta.'
          />
        )}
        <s.Form onSubmit={handleSubmit(onSubmit)}>
          {!submit && (
            <s.InputContainer>
              <Input
                type='password'
                placeholder="Insira sua senha"
                register={register}
                errors={errors.password}
              >
                senha
              </Input>

              <Input
                type='password'
                placeholder="Confirme sua senha"
                id="confirmPassword"
                register={register}
                errors={errors.confirmPassword}
              >
                Confirmar senha
              </Input>
            </s.InputContainer>
          )}

          {/* <s.SubmitContainer> */}
          <Submit>
            {submit ? (
              <>
                Concluir
              </>
            ) : (
              <>
                Alterar senha
              </>
            )}
          </Submit>
          {/* </s.SubmitContainer> */}
        </s.Form>
      </s.Wrapper>
    </FormLayout>
  )
}