import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.span`
  margin-bottom: 1rem;
`;

export const Icon = styled.span`
  justify-content: left;
  margin-bottom: 1rem;
`;

export const Title = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 1.35rem;
  line-height: 2.1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutral100};

  @media (min-width: 425px) {
    font-size: 1.5rem;
  }
`;

export const SubTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 0.85rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral500};

  @media (min-width: 425px) {
    font-size: 1rem;
  }
`;
