import styled from "styled-components";

export const Header = styled.header`
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.neutral0};

  padding-bottom: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Route = styled.h1`
  font-weight: 600;
  font-size: 1.5rem;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  padding: 0.5rem;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral200};

  div {
    display: flex;
    gap: 1rem;
  }
`;

export const Validate = styled.span`
  color: ${({ theme }) => theme.colors.neutral500};
  font-weight: 400;
  font-size: 14px;
  align-self: center;
`;
