// hooks
import React, { useEffect, useState } from 'react'
import { localizedStrings } from "../../../../../constants/localizedStrings";
// react-hook-forms
import { Controller, useForm } from 'react-hook-form'

// zod
import { AddContactFormData, AddContactFormSchema } from 'src/types/add-contacts-form'
import { zodResolver } from '@hookform/resolvers/zod'

// react phone number input
import ReactPhoneInput from "react-phone-number-input";

// components
import Input from 'src/components/Input'
import { AlertDropdown } from '../../AlertDropdown'

// styles
import * as s from './style'

// utils
import SucessUser from 'src/utils/icons/SucessUser'
import api from 'src/services/api'
import { toast } from 'react-toastify'
import { DataProps } from 'src/pages/Clients/data'

export const AddUser = ({ onCancel, refresh, editedContact }: { editedContact?: DataProps, onCancel?: () => void, refresh?: (a?:string,b?:boolean,c?:boolean,d?:boolean,e?:boolean) => void }) => {
  const [displayAlert, setDisplayAlert] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }, // need to show the form validation errors
  } = useForm<AddContactFormData>({
    resolver: zodResolver(AddContactFormSchema)
  })

  const onSubmit = async (data: AddContactFormData) => {
    if (!displayAlert) {
      if (editedContact) {
        try {
          await api.put("/numberr/" + editedContact.id, {
            text: data.description,
            number: String(data?.cellphone).replace(/\D/g, ""),
          });
          toast.success(localizedStrings.mainLayout.components.controls.addContact.numberUpdatedSuccessfully);
          refresh?.(undefined, undefined, undefined, undefined,true)
        } catch(err: any) {
          if (err?.response?.data?.error) {
            const message = typeof err.response.data.error == "object"?err.response.data.error.message:err.response.data.error
            if(message =="ERR_NUMBER_NOPLAN_FAILED"){
              toast.error("Você não possui plano")
            }else if(message =="ERR_NUMBER_INVALID_FAILED"){
              toast.error("Numero Invalido")
            }else if(message =="ERR_NUMBER_LIMIT_FAILED"){
              toast.error("Limite maximo de numeros criados, edite um numero cadastrado existente.")
            }else if(message =="ERR_NUMBER_ALREADY_FAILED"){
              toast.error("Numero ja em uso em sua conta ou outra conta")
            }else if (message == "ERR_NO_PERMISSION") {
              toast.error("Você não tem permissão para isso");
            }
                       return;
                     }
          toast.error(localizedStrings.mainLayout.components.controls.addContact.failToCreateNumber)
          return;
        }
      }
      else {
        try {
          await api.post("/numberr", {
            text: data.description,
            number: String(data?.cellphone).replace(/\D/g, ""),
          });
          toast.success(localizedStrings.mainLayout.components.controls.addContact.yourNumberWasAddedSuccessfully);
          refresh?.()
        } catch(err: any) {
          if (err?.response?.data?.error) {
            const message = typeof err.response.data.error == "object"?err.response.data.error.message:err.response.data.error
if(message =="ERR_NUMBER_NOPLAN_FAILED"){
  toast.error("Você não possui plano")
}else if(message =="ERR_NUMBER_INVALID_FAILED"){
  toast.error("Numero Invalido")
}else if(message =="ERR_NUMBER_LIMIT_FAILED"){
  toast.error("Limite maximo de numeros criados, edite um numero cadastrado existente.")
}else if(message =="ERR_NUMBER_ALREADY_FAILED"){
  toast.error("Numero ja em uso em sua conta ou outra conta")
}
           return;
         }
          toast.error(localizedStrings.mainLayout.components.controls.addContact.failToCreateNumber)
          return;
        }
      }
      return setDisplayAlert(true)
    }

    // close modal
    onCancel?.()
  }
  useEffect(()=>{
    setValue('cellphone', String(editedContact?.cellphone))
  },[])
  return (
    <s.Form onSubmit={handleSubmit(onSubmit)}>
      {displayAlert ? (
        <AlertDropdown icon={<SucessUser />} title={localizedStrings.mainLayout.components.controls.addContact.yourNumberWasAddedSuccessfully} />
      ) : (
        <React.Fragment>
          <s.InputContainer $hasError={errors.cellphone && true}>
            <label htmlFor="cellphone">{localizedStrings.number}</label>
            <Controller
              control={control}
              name="cellphone"
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <ReactPhoneInput
                  {...field as any}
                  placeholder={localizedStrings.mainLayout.components.controls.addContact.insertContactNumber}
                  inputStyle={{
                    width: '100%',
                    borderColor: errors.cellphone ? '#445568' : '#E5E9EE'
                  }}
                  buttonStyle={{
                    borderColor: errors.cellphone ? '#445568' : '#E5E9EE'
                  }}
                  containerStyle={{
                    marginTop: 9
                  }}
                />
              )}
            />
            {/*errors.cellphone && <span>{errors.cellphone.message}</span>*/}
          </s.InputContainer>

          <Input
            type='description'
            placeholder={localizedStrings.mainLayout.components.controls.addContact.insertDescriptionToYourNumber}
            register={register}
          //  errors={errors.description}
            defaultValue={editedContact?.observation}
          >
            {localizedStrings.description}
          </Input>

          {/*<Text as="label" size="2">
            <Flex gap="2" justify='between'>
              Padrão
              <input type="checkbox" {...register("defaultContact")} />
            </Flex>
          </Text>*/}

          <s.SubmitWrapper>
            <s.Reset type="reset" value={localizedStrings.cancel} onClick={() => { onCancel?.() }} />
            <s.Submit type="submit" value={editedContact ? localizedStrings.update : localizedStrings.add} />
          </s.SubmitWrapper>
        </React.Fragment>
      )}
    </s.Form>
  )
}