import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;

  display: flex;
  
  background-color: ${({ theme }) => theme.colors.neutral1000};
  
  @media (min-width: 1440px) {
    gap: 1rem;
    padding: 1rem;
  }
`

export const Main = styled.main`
  position: absolute;
  inset: 0;
  left: 18%;
  border-radius: 0;

  background-color: ${({ theme }) => theme.colors.neutral1100};
  padding: .85rem;

  flex: 1;

  @media (min-width: 768px) {
    inset: 1rem;
    left: calc(10% + 2rem);
    border-radius: 1.25rem;
  }
  
  
  @media (min-width: 1024px) {
    inset: 1rem;
    left: calc(5% + 2rem);
    padding: 1.5rem;
    border-radius: 1.25rem;
  }
`