import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 32px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.colors.neutral900};
  padding: 12px 16px;

  input[type="text"] {
    width: 100%;
    border: 0;
    outline: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  input[type="submit"] {
    width: 100%;
    padding: 13px 16px;
    border-radius: 50px;

    background-color: ${({ theme }) => theme.colors.neutral100};
    color: ${({ theme }) => theme.colors.neutral1100};

    border: 0;
    cursor: pointer;
  }

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral500};
    cursor: pointer;
  }
`;
