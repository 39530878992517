import { z } from "zod";

// Schema -> data structure representation
export const RecoveryPasswordFormSchema = z
  .object({
    password: z.string().nonempty("Você precisa digitar sua nova senha"),
    confirmPassword: z.string().nonempty("Você precisa repetir sua nova senha"),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: "As senhas precisam ser iguais",
    path: ["confirmPassword"],
  });

// type to remove typescript errors by reactHooks
export type RecoveryPasswordFormData = z.infer<
  typeof RecoveryPasswordFormSchema
>;

export const SendEmailFormSchema = z.object({
  email: z
    .string()
    .nonempty("Você precisa digitar seu e-mail")
    .email("E-mail inválido!"),
});

// type to remove typescript errors by reactHooks
export type SendEmailFormData = z.infer<typeof SendEmailFormSchema>;
