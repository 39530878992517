// hooks
import React, { useEffect, useRef, useState } from 'react'
import { useSortableData } from 'src/hooks/useSortableData'

// radix
import { Checkbox, Flex } from '@radix-ui/themes'

// styles
import * as s from './style'
import { Alert, HorizontalControl } from 'src/utils/icons'

// components
import { Modal } from './Modal'
import { AddUser } from '../Controls/AddContact'
import { Button } from '../Button'
import api from 'src/services/api'
import { toast } from 'react-toastify'
import { DataProps } from 'src/pages/Clients/data'
import { useAuth } from 'src/context/Auth/AuthContext'
import { copy } from 'src/utils/copy'

// types
type TableProps = {
  data: DataProps[],
  blockedContactsTab?: boolean,
  setData?: React.Dispatch<React.SetStateAction<DataProps[]>>;
  refresh?: () => void;
  setSelectedContacts?: (data: DataProps[]) => void;
  showDeleteModal?: boolean;
  setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
  bulkDelete?:()=>void,
  SetOnline?:React.Dispatch<React.SetStateAction<number>>;
  setCount?:React.Dispatch<React.SetStateAction<number>>;
  showAll?:boolean
}

export const Table = ({ showAll,setCount,data, blockedContactsTab,SetOnline, setShowDeleteModal,bulkDelete, showDeleteModal, setData, refresh, setSelectedContacts }: TableProps) => {
  const [selectAll, setSelectAll] = useState(false)
  const [checkedState, setCheckedState] = useState<boolean[]>(data.map(() => false));
  const [selectDeleteContact, setSelectDeleteContact] = useState<undefined | number>(undefined)
  const [verticalIconDropdown, setVerticalIconDropdown] = useState<number | null>(null)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [editedContact, SetEditedContact] = useState<DataProps | undefined>(undefined);
  const [connections,setConnection] = useState({active:false,connections:[]})
  const { items, requestSort, sortConfig } = useSortableData(data) // sort table
  const { user } = useAuth();
  // add class when sort table
  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // checkbox check all
  const handleCheckAll = () => {
    setSelectAll(!selectAll)

    setCheckedState(checkedState.map(item => !selectAll));

    const updatedData = data.map((idx => { return { ...idx, checked: !selectAll } }));

    setData?.(updatedData);

    const everyIsFalse = updatedData.some(idx => idx.checked === false);

    setSelectedContacts?.(everyIsFalse ? [] : updatedData);
  }

  const quebrarTexto = (texto: string) => {
    const caracteresPorLinha = 17;
    let textoQuebrado = [];
    for (let i = 0; i < texto.length; i += caracteresPorLinha) {
      textoQuebrado[textoQuebrado.length] = texto.substring(i, i + caracteresPorLinha);
      textoQuebrado[textoQuebrado.length] = <br />
    }
    return textoQuebrado;
  }

  // checkbox check
  const handleCheckbox = (position: number) => {
    setCheckedState(prevState =>
      prevState.map((item, idx) =>
        idx === position ? !item : item
      )
    );

    data[position].checked = !data[position].checked
    setSelectedContacts?.(data.filter(idx => idx.checked === true))
  };

  // handle vertical icon dropdown
  const handleVerticalIconDropdown = (index: any) => {
    setVerticalIconDropdown(dropIndex => {
      return dropIndex === index ? null : index
    });
  }

  // handle vertical icon dropdown close when click out
  const buttonRef = useRef<HTMLTableSectionElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setVerticalIconDropdown(null)
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [buttonRef]);
  return (
    <s.TableContainer>
      <s.TableHead>
        <tr>
          <td style={{ width: 50 }}>
            <Flex align='center'>
              <Checkbox checked={selectAll} onClick={handleCheckAll} />
            </Flex>
          </td>
          {/*<td
            onClick={() => requestSort('name')}
            className={getClassNamesFor('name')}
            style={{ cursor: 'pointer' }}
          >
            Nome
          </td>
          <td
            onClick={() => requestSort('email')}
            className={getClassNamesFor('email')}
            style={{ cursor: 'pointer' }}
          >
            E-mail
  </td>*/}
          {user?.profile == "admin" && showAll && (
            <React.Fragment>
              <td
                onClick={() => requestSort('name')}
                className={getClassNamesFor('name')}
                style={{ cursor: 'pointer' }}
              >
                Nome
              </td>
              <td
                onClick={() => requestSort('email')}
                className={getClassNamesFor('email')}
                style={{ cursor: 'pointer' }}
              >
                E-mail
              </td>
            </React.Fragment>)
          }
          <td className={getClassNamesFor('cellphone')}>Número</td>
          <td
            className={getClassNamesFor('observation')}
          >Observação</td>
          {/* <td>Etiquetas</td> TODO: Only on pro */}
          {user?.profile == "admin" && showAll && (
            <React.Fragment>
              <td style={{ width: 100 }}>Beta</td>
              <td style={{ width: 100 }}>V2</td>
            </React.Fragment>
          )}
          <td style={{ width: 100 }}>Padrão</td>
          {user?.profile == "admin" && showAll &&<td style={{ width: 100 }}>Status</td>}
          <td style={{ width: 25 }}></td>
        </tr>
      </s.TableHead>
      <s.TableBody ref={buttonRef}>
        {items.map((contact, index) => (
          <tr key={index}>
            <td data-label="Selecionar">
              <Flex align='center'>
                <Checkbox checked={checkedState[index]} onClick={() => handleCheckbox(index)} />
              </Flex>
            </td>
            {/* <td data-label="Nome">
              <div>
        */ }

            {/* TODO: picture only on PRO */}
            {/* {contact.profilePicture ? (
                  <img src={contact.profilePicture} alt={`${contact.name} profile`} width={32} />
                ) : (
                  <UserProfilePicture />
                )} */}
            {/*   {contact.name}
              </div>
            </td>
          <td data-label="E-mail">
              {contact.email}
              <div></div>
            </td>
            */}
            {user?.profile == "admin" && showAll && <>
              <td data-label="Nome" onClick={()=>copy(contact.name)}>
                <div>
                  {contact.name && quebrarTexto(contact.name)}
                </div>
              </td>
              <td data-label="E-mail" onClick={()=>copy(contact.email)}>
                {contact.email && quebrarTexto(contact.email)}
                <div></div>
              </td></>}
            <td data-label="Número" onClick={()=>copy(contact.cellphone)}>{contact.cellphone}</td>
            <td data-label="Etiquetas">
              <div>
                {contact.observation && quebrarTexto(contact.observation)}
              </div>
            </td>

            {/* TODO: td das tags na PRO */}
            {/* <td data-label="Etiquetas">
              <div>
                {contact.tags?.map((tag: string) => (<Tag key={tag}>{tag}</Tag>))}
              </div>
            </td> */}
            {user?.profile == "admin" && showAll && <><td data-label="Beta">{contact.beta ? 'Sim' : 'Não'}</td>
              <td data-label="V2">{contact.isV2 ? 'Sim' : 'Não'}</td></>


            }
            <td data-label="Padrão">{contact.default ? 'Sim' : 'Não'}</td>
            {user?.profile == "admin" && showAll &&<td data-label="Status" onClick={contact.status == "online"?()=>{setConnection({active:true,connections:contact.connections||[]})}:()=>{}}>{contact.status}</td>}
            <td
              data-label=""
              style={{ cursor: 'pointer' }}
              onClick={() => handleVerticalIconDropdown(index)}
            >
              <HorizontalControl />

              {verticalIconDropdown === index && (
                <s.Dropdown>
                  <ul>
                    {blockedContactsTab ? (
                      <>
                        <li>Desbloquear contato</li>
                        <li>Excluir contato</li>
                      </>
                    ) : (
                      <>
                        <li onClick={() => { SetEditedContact(contact); setEditModal(true); }}>Editar contato</li>
                        <li onClick={() => { setSelectDeleteContact(contact.id); setShowDeleteModal?.(true); }}>Excluir contato</li>
                      </>
                    )}

                  </ul>
                </s.Dropdown>
              )}
            </td>
          </tr>
        ))}
      </s.TableBody>

      {/* vertical dropdown modals */}
      {editModal && (
        <Modal state={setEditModal}>
          <AddUser refresh={refresh} onCancel={() => { setEditModal(false) }} editedContact={editedContact} />
        </Modal>
      )}

      {showDeleteModal && (
        <Modal state={setShowDeleteModal}>
          <s.ContainerFlex>
            <Alert />
            <div>
              <h1>Deseja realmente excluir esse numero?</h1>
              <h2>O numero não poderá ser utilizado.</h2>
            </div>
            <button onClick={async () => {

              if(selectDeleteContact){try {
                selectDeleteContact && await api.delete("/numberr/" + selectDeleteContact);
                setData?.(data.filter(elmenet => {if(elmenet.id == selectDeleteContact){
                  setCount?.(a=>a-1);
                  return false;
                }
              return true
              }))
                setCheckedState(data.filter(elmenet => elmenet.id !== selectDeleteContact).map(a=>false));
               if(data.find(elmenet => elmenet.id == selectDeleteContact)?.status=="online") SetOnline?.(evt=>evt-1);
                toast.success("sucesso ao deletar")
              } catch {
                toast.error("falha ao deletar")
              }}else{
                try{
                 await bulkDelete?.();
                  setSelectAll(false)
                  setCheckedState(data.map(()=>false))
                }catch{}
              }
              setSelectDeleteContact(undefined);
              setShowDeleteModal?.(false)

            }}>Excluir</button>
            <span onClick={() =>
              setShowDeleteModal?.(false)
            }>Cancelar</span>
          </s.ContainerFlex>
        </Modal>
      )}
      {connections.active&&<Modal state={(b:boolean)=> setConnection(a=>{return{...a,active:b}})} css={{overflow:"auto",maxHeight:"35em"}}><s.ContainerFlex  style={{textAlign:"center"}}>
        {
          connections.connections.map((elmene:any)=>{return (
            <>
            {elmene?.dados?.uuidnovo&&<p>Computador:{elmene.dados.uuidnovo}</p>}
            {elmene?.dados?.ip&&<p>ip:{elmene.dados.ip}</p>}
            {elmene?.dados?.sistema&&<p>Sistema:{elmene.dados.sistema}</p>}
            {elmene?.dados?.version&&<p>Sistema:{elmene.dados.version}</p>}
            {elmene?.dados?.userAgent&&<p>userAgent:{elmene.dados.userAgent}</p>}
            <p>------------------------------------------------------</p>
            </>
          )})
        }
        </s.ContainerFlex></Modal>}
    </s.TableContainer>
  )
}